import gql from 'graphql-tag';
import { GQLFunction } from '../../types';
import { GQL } from 'utils/graphql';
import { getLanguage, isProduction } from 'utils/common';

export const ServiceLinksFragment = gql`
  fragment ServiceLinks on services_services_Entry {
    id
    uri
    title
    slug
    localized {
      slug
      language
      siteId
    }
    teamHero {
      ... on teamHero_hero_BlockType {
        id
        logo {
          url @transform(height: 33, mode: "fit", immediately: true)
          width
          height
        }
      }
    }
  }
`;

export const GetServiceLinks = gql`
  query getServiceLinks($lang: [QueryArgument]) {
    entries(siteId: $lang, type: "services") {
      ...ServiceLinks
    }
  }
  ${ServiceLinksFragment}
`;

export const GetCommon = gql`
  query getCommon($lang: [QueryArgument]) {
    common: entries(siteId: $lang, type: "common") {
      ... on common_common_Entry {
        id
        mailchimpAudienceId
        googleTagManagerId
        termsAndConditionsPdf {
          url
        }
        aboutHippoFooter
      }
    }
    jobOffers: entryCount(siteId: $lang, type: "jobOffers", status: ${
      !isProduction()
        ? '["disabled", "pending", "expired", "live"]'
        : '["live"]'
    })
  }
`;

export const getCommonData: GQLFunction = async (context, variables) => {
  const { data } = await GQL(
    GetCommon,
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};

export const getServiceLinksData: GQLFunction = async (context, variables) => {
  const { data } = await GQL(
    GetServiceLinks,
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};
