import gql from 'graphql-tag';
import { GQLFunction } from '../../types';
import { GQL } from 'utils/graphql';
import { getLanguage } from 'utils/common';

export const ServicesOverviewQuery = gql`
  query ServicesOverviewQuery($lang: [QueryArgument]) {
    overview: entry(siteId: $lang, type: "service", withStructure: true) {
      id
      title
      localized {
        uri
        language
      }
      ... on service_service_Entry {
        id
        seoTitle
        seoDescription
        seoImage {
          url
        }
        serviceTitle
        serviceIntro
        howBlock {
          ... on howBlock_how_BlockType {
            id
            howTitle
            howSubtitle
          }
        }
        howContent {
          ... on howContent_howContent_BlockType {
            id
            howImage {
              url
              width
              height
              focalPoint
            }
            howTitle
            howSubtitle
          }
        }
        lookForMoreBlock {
          ... on lookForMoreBlock_lookForMore_BlockType {
            id
            lookForMoreTitle
            subtitle
            callToAction {
              ... on callToAction_callToAction_Entry {
                id
                ctaUrl
                ctaIcon
                ctaType
                title
              }
            }
          }
        }
        casesBlock {
          ... on casesBlock_cases_BlockType {
            id
            casesTitle
            casesSubtitle
            selectedCases {
              ... on cases_cases_Entry {
                id
                title
                caseHero {
                  url
                }
                caseIntro
                uri
                caseThumbCta {
                  ... on callToAction_callToAction_Entry {
                    id
                    title
                    ctaType
                  }
                }
                caseServices {
                  ... on services_services_Entry {
                    id
                    title
                    teamHero {
                      ... on teamHero_hero_BlockType {
                        id
                        logo {
                          url
                          width
                          height
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ctaBanner {
          ... on ctaBanner_ctaBanner_Entry {
            id
            title
            bannerTitle
            bannerDescription
            bannerStyle
            bannerButton {
              ... on callToAction_callToAction_Entry {
                id
                title
                ctaType
                ctaUrl
              }
            }
          }
        }
      }
    }
    services: entries(siteId: $lang, type: "services", withStructure: true) {
      ... on services_services_Entry {
        id
        title
        uri
        teamDescription {
          ... on teamDescription_teamDescription_BlockType {
            id
            teamDescriptionText
          }
        }
        teamHero {
          ... on teamHero_hero_BlockType {
            id
            logo {
              url
              width
              height
              focalPoint
            }
          }
        }
        serviceCta {
          ... on callToAction_callToAction_Entry {
            id
            ctaType
            title
          }
        }
      }
    }
  }
`;

export const getServicesOverviewPageData: GQLFunction = async (
  context,
  variables
) => {
  const { data } = await GQL(
    ServicesOverviewQuery,
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};
