import React from 'react';
import {
  CookieBanner,
  FacebookLink,
  Image,
  InstagramLink,
  LanguageSwitcher,
  Link,
  LinkedInLink,
  RedactorContent,
} from 'components';
import { contactInfo } from '../../config/consts';
import { strings } from '../consts/translations';
import { useRouter } from 'next/router';
// import '../styling/footer.scss';
export const Footer = ({
  serviceLinksData,
  commonData,
  cookieBannerData,
  localized,
}: {
  serviceLinksData: any;
  commonData: any;
  cookieBannerData: any;
  localized: any;
}) => {
  const router = useRouter();
  return (
    <>
      <footer>
        <div id="footer" className="grid">
          <Link href={`/${router.locale}`} id="logo-footer">
            <Image
              alt="Logo"
              data-src="/unikoo-logo-dark-slogan-left-nw.svg"
              width={'300px'}
              height={'111px'}
            />
          </Link>
          <div className="about-hippo-footer">
            <Link
              id="hippo-by-unikoo-logo"
              href="https://hippocommunicatie.be/"
              target="_blank"
            >
              <Image
                alt="HiPPO by Unikoo"
                data-src="/hippo/RGB-LOGO-TR-POS.svg"
                width={'828px'}
                height={'90px'}
              />
            </Link>
            <RedactorContent
              html={commonData.common[0].aboutHippoFooter}
              className="about-hippo-text"
            />
          </div>
          <div id="contact">
            <h2>Offices</h2>
            <h3>
              <a href="https://g.page/Unikoo" target="_blank" rel="noreferrer">
                Unikoo Antwerpen
              </a>
            </h3>
            {/* <h3><a href="https://g.page/meetdistrict-ghelamco-arena" target="_blank" rel="noreferrer">Unikoo Gent –
            MeetDistrict</a></h3> */}
            <h3>
              <a
                href="https://goo.gl/maps/tBJfkQbWQDh7EexV7"
                target="_blank"
                rel="noreferrer"
              >
                Unikoo Limburg
              </a>
            </h3>
            <h3>
              <a
                href="https://goo.gl/maps/JJ2k5myk3aWCtJB5A"
                target="_blank"
                rel="noreferrer"
              >
                Unikoo Oost-Vlaanderen
              </a>
            </h3>
            <br />
            <br />
            <a href={`mailto:${contactInfo.email}`}>
              <h3>{contactInfo.email}</h3>
            </a>
            <a href={`tel:${contactInfo.phone}`}>
              <h3>{contactInfo.phone}</h3>
            </a>
          </div>
          <div id="icons">
            {serviceLinksData.entries.map((team) => (
              <Image
                key={team.id}
                height="33px"
                width="33px"
                alt={team.title}
                data-src={team.teamHero[0]?.logo[0]?.url}
              />
            ))}
          </div>
          <div id="links">
            <Link href={`/${router.locale}/`}>
              <span>Home</span>{' '}
            </Link>
            <Link href={`/${router.locale}/about`}>
              <span>About</span>{' '}
            </Link>
            <Link href={`/${router.locale}/services`}>
              <span>Services</span>{' '}
            </Link>
            <Link href={`/${router.locale}/team`}>
              <span>Team</span>{' '}
            </Link>
            <Link href={`/${router.locale}/career`}>
              <span>Career</span>{' '}
            </Link>
            {/*<Link href={`/${router.locale}/cases`}><span>Cases</span> </Link>*/}
            <Link href={`/${router.locale}/blog`}>
              <span>Blog</span>{' '}
            </Link>
            <Link href="https://unikoo.prezly.com/">
              <span>{strings[router.locale].press}</span>
            </Link>
            <Link href={`/${router.locale}/contact`}>
              <span>Contact</span>{' '}
            </Link>
          </div>
          <div id="teams">
            {serviceLinksData.entries.map((team) => (
              <Link
                key={team.id}
                href={`/${router.locale}/services/${team.slug}`}
              >
                <span>{team.title}</span>
              </Link>
            ))}
            <LanguageSwitcher type={'footer'} localized={localized} />
          </div>
          <div id="socials-footer">
            <FacebookLink />
            <InstagramLink />
            <LinkedInLink />
          </div>
          <p id="rights">
            All rights reserved. Copyright Unikoo {new Date().getFullYear()}{' '}
            <span> </span>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={commonData.common[0].termsAndConditionsPdf[0].url}
              id="terms"
            >
              {strings[router.locale].generalTerms}
            </Link>
            <Link href={`/${router.locale}/privacy`} id="privacy">
              Privacy Policy
            </Link>
          </p>
        </div>
      </footer>
      <CookieBanner cookieBannerData={cookieBannerData} />
    </>
  );
};
export default Footer;
