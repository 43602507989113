import React, { useCallback, useState } from 'react';
import { PrimaryActionButton } from './Buttons';
import { RedactorContent } from './redactor-content';
import { useDropzone } from 'react-dropzone';
import PhoneInput from 'react-phone-number-input';
// import '../styling/form.scss';

export type Form = {
  title: string;
  formDescription: string;
  formFields: FormField[];
};

type FormField = {
  id: string;
  fieldType: string;
  label: string;
  actionLabel: string;
  required: string;
  disabled?: boolean;
  value?: string;
};

const SubmitForm = async (ev: any, originalAction, ref, reference) => {
  ev.preventDefault();
  const formData = new FormData(ref.current);
  const data = {
    attachments: [],
    formName: '',
  };

  // @ts-ignore
  // Get the label of the field, remove the * for required and set that as the key of the field
  for (const [key, value] of formData.entries()) {
    if (key === 'phone') {
      data[
        document
          .getElementsByName(key)[0]
          ?.parentElement?.previousElementSibling?.textContent.replace('*', '')
          .trim()
      ] = value;
    } else if (key === 'phoneCountry' || key.includes('group')) {
      data[key] = value;
    } else if (!key.includes('file')) {
      data[
        document
          .getElementById(key)
          ?.previousElementSibling?.textContent.replace('*', '')
          .trim()
      ] = value;
    } else {
      data.attachments.push(value);
    }
  }
  if (reference) {
    data.formName = reference;
  }
  await originalAction(data);
  ref.current?.reset();
};

export const Form = ({
  form,
  action,
  reference,
  submitDisabled = false,
  spinnerProps,
}: {
  form: Form;
  action: (ev: any) => void;
  reference?: string;
  submitDisabled?: boolean;
  spinnerProps: { spinnerCss: string; spinnerSize: number };
}) => {
  const ref = React.createRef<HTMLFormElement>();
  return (
    <form
      ref={ref}
      className="form"
      onSubmit={(ev) => SubmitForm(ev, action, ref, reference)}
    >
      <h2>{form.title}</h2>
      {form.formDescription ? (
        <RedactorContent
          className="form-description"
          html={form.formDescription}
        />
      ) : (
        ''
      )}

      {form.formFields.map((formField) => (
        <Field
          data={formField}
          key={formField.id}
          disabled={submitDisabled}
          spinnerProps={spinnerProps}
        />
      ))}
    </form>
  );
};

const FileUpload = (props: FormField) => {
  const [fileName, setFileName] = useState('');
  const onDrop = useCallback((acceptedFile) => {
    if (acceptedFile?.length) {
      setFileName(acceptedFile[0]?.name);
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div className="form-field form-field-file grid" {...getRootProps()}>
      <label
        htmlFor={props.fieldType + '_' + props.id}
        id="label"
        dangerouslySetInnerHTML={{
          __html:
            props.required == 'true'
              ? `${props.label} <sup>*</sup>`
              : props.label,
        }}
      />
      <input
        {...getInputProps()}
        multiple={false}
        name={props.fieldType + '_' + props.id}
        id={props.fieldType + '_' + props.id}
        required={props.required == 'true'}
      />
      <input
        type="text"
        value={fileName}
        className="form-field input-text-file"
        aria-labelledby="label"
        required={props.required == 'true'}
      />
      <button className="cta choose-file-btn">{props.actionLabel}</button>
    </div>
  );
};

const Field = ({
  data,
  disabled,
  spinnerProps,
}: {
  data: FormField;
  disabled: boolean;
  spinnerProps: { spinnerCss: string; spinnerSize: number };
}) => {
  switch (data.fieldType) {
    case 'submit':
      return (
        <div className="form-field submit">
          <PrimaryActionButton
            disabled={disabled}
            label={data.label}
            spinnerCss={spinnerProps.spinnerCss}
            spinnerSize={spinnerProps.spinnerSize}
          />
        </div>
      );
    case 'textarea':
      return (
        <div className="form-field textarea">
          <label
            htmlFor={data.fieldType + '_' + data.id}
            dangerouslySetInnerHTML={{
              __html:
                data.required == 'true'
                  ? `${data.label} <sup>*</sup>`
                  : data.label,
            }}
          />
          <textarea
            required={data.required == 'true'}
            id={data.fieldType + '_' + data.id}
            name={data.fieldType + '_' + data.id}
          />
        </div>
      );
    case 'file':
      return <FileUpload {...data} />;
    case 'name':
      return (
        <Input
          name="name"
          autoComplete="name"
          label={data.label}
          fieldType={data.fieldType}
          id={data.fieldType + '_' + data.id}
          required={data.required == 'true'}
        />
      );
    case 'fname':
      return (
        <Input
          name="fname"
          autoComplete="given-name"
          label={data.label}
          fieldType={data.fieldType}
          id={data.fieldType + '_' + data.id}
          required={data.required == 'true'}
        />
      );
    case 'lname':
      return (
        <Input
          name="lname"
          autoComplete="family-name"
          label={data.label}
          fieldType={data.fieldType}
          id={data.fieldType + '_' + data.id}
          required={data.required == 'true'}
        />
      );
    case 'email':
      return (
        <Input
          name="email"
          autoComplete="email"
          label={data.label}
          fieldType={data.fieldType}
          id={data.fieldType + '_' + data.id}
          required={data.required == 'true'}
        />
      );
    case 'tel':
      return (
        <div className="form-field">
          <label
            htmlFor={data.fieldType + '_' + data.id}
            dangerouslySetInnerHTML={{
              __html:
                data.required == 'true'
                  ? `${data.label} <sup>*</sup>`
                  : data.label,
            }}
          />
          <PhoneInput
            name="phone"
            autoComplete="tel"
            label={data.label}
            fieldType={data.fieldType}
            id={data.fieldType + '_' + data.id}
            required={data.required == 'true'}
            onChange={() => {
              return;
            }}
          />
        </div>
      );
    case 'organization':
      return (
        <Input
          name="organization"
          autoComplete="organization"
          label={data.label}
          fieldType={data.fieldType}
          id={data.fieldType + '_' + data.id}
          required={data.required == 'true'}
        />
      );
    case 'hidden':
      return <input type="hidden" value={data.value} name={data.label} />;
    default:
      return (
        <div className="form-field">
          <label
            htmlFor={data.fieldType + '_' + data.id}
            dangerouslySetInnerHTML={{
              __html:
                data.required == 'true'
                  ? `${data.label} <sup>*</sup>`
                  : data.label,
            }}
          />
          <input
            type={data.fieldType}
            id={data.fieldType + '_' + data.id}
            required={data.required == 'true'}
            name={data.fieldType + '_' + data.id}
          />
        </div>
      );
  }
};

type InputProps = {
  id: string;
  required?: boolean;
  label: string;
  fieldType: string;
  name: string;
  autoComplete: string;
};

const Input = ({
  required,
  label,
  fieldType,
  name,
  autoComplete,
}: InputProps) => (
  <div className="form-field">
    <label
      htmlFor={name}
      dangerouslySetInnerHTML={{
        __html: required ? `${label} <sup>*</sup>` : label,
      }}
    />
    <input
      type={fieldType}
      id={name}
      required={required}
      name={name}
      autoComplete={autoComplete}
    />
  </div>
);
