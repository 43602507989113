import { FC } from 'react';
import { ShapeProps } from './types';

const ArchShape: FC<ShapeProps> = ({ fillColor, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 182 137"
      className={className || ''}
    >
      <path
        d="M270.416 46c2.164 6.46 5.627 12.608 10.423 18.478 22.097 26.837 67.947 21.122 83.167-10.125 1.65-3.348 2.79-5.953 3.654-8.353H420c-7.94 48.072-48.406 82.079-100.901 82-52.614-.12-93.077-34.008-101.099-82h52.416Z"
        transform="rotate(-150 208.677 97.269)"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default ArchShape;
