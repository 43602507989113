import gql from 'graphql-tag';
import { GQLFunction } from '../../types';
import { GQL } from 'utils/graphql';
import { getLanguage, isProduction } from 'utils/common';
export const GetJobOffersQuery =
  () => gql`query getJobOffers ($lang:[QueryArgument], , $filters:[EntryCriteriaInput]) {
jobOffers: entries(siteId: $lang, type: "jobOffers", relatedToEntries: $filters, orderBy: "ordernummer ASC", status: ${
    !isProduction() ? '["disabled", "pending", "expired", "live"]' : '["live"]'
  }) {
  id
  localized {
    slug
    language
    siteId
  }
  ... on jobOffers_jobOffers_Entry {
    id
    title
    slug
    uri
    jobOfferDescription
    location {
      ... on locaties_locaties_Entry {
        id
        stad
        title
      }
    }
    jobOfferButton {
      ... on callToAction_callToAction_Entry {
        id
        title
        ctaType
      }
    }
    jobOfferService {
      ... on services_services_Entry {
        id
        teamHero {
          ... on teamHero_hero_BlockType {
            id
            logo {
              url @transform (width:70, mode: "fit", immediately: true)
              width
              height
            }
          }
        }
      }
    }
  }
}
}
`;
export const GetCareerOverviewQuery = () => gql`
  query getCareerOverview($lang: [QueryArgument]) {
    teams: entries(siteId: $lang, type: "services") {
      ... on services_services_Entry {
        id
        slug
        title
        id
      }
    }
    locations: entries(siteId: $lang, type: "locaties") {
      ... on locaties_locaties_Entry {
        id
        slug
        title
        id
      }
    }
    overview: entry(siteId: $lang, type: "careerOverview") {
      ... on careerOverview_careerOverview_Entry {
        id
        localized {
          uri
          language
        }
        careerTitle
        careerWhyTitle
        careerWhySubtitle
        careerWhyHero {
          url @transform(width: 778, mode: "fit", immediately: true)
          width
          height
          focalPoint
        }
        careerWhyDescription
        careerWelcomeTitle
        careerWelcomeDescription
        careerIntro
        careerImagesRow {
          id
          url @transform(width: 800, mode: "fit", immediately: true)
          width
          height
          focalPoint
        }
        careerHero {
          url @transform(width: 800, mode: "fit", immediately: true)
          width
          height
          focalPoint
        }
        seoTitle
        seoImage {
          id
          url
        }
        seoDescription
        careerTroevenTitle
        careerTroeven {
          ... on careerTroeven_troefBlock_BlockType {
            id
            troefIcon
            troefTitle
            troefDescription
          }
        }
        ctaBannerPositions {
          ... on ctaBanner_ctaBanner_Entry {
            id
            title
            bannerTitle
            bannerDescription
            bannerColor
            bannerStyle
            bannerExtraClasses
            bannerButton {
              ... on callToAction_callToAction_Entry {
                id
                title
                ctaType
                ctaUrl
                ctaTargetBlank
              }
            }
          }
        }
        ctaBanner {
          ... on ctaBanner_ctaBanner_Entry {
            id
            title
            bannerTitle
            bannerDescription
            bannerColor
            bannerStyle
            bannerExtraClasses
            bannerButton {
              ... on callToAction_callToAction_Entry {
                id
                title
                ctaType
                ctaUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const getCareerOverviewPageData: GQLFunction = async (
  context,
  variables
) => {
  const { data } = await GQL(
    GetCareerOverviewQuery(),
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};
export const getJobOffersData: GQLFunction = async (context, variables) => {
  const { data } = await GQL(
    GetJobOffersQuery(),
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};
