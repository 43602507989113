import { FC } from 'react';
import { ShapeProps } from './types';

const RectangleShape: FC<ShapeProps> = ({ fillColor, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 83"
      className={className || ''}
    >
      <path
        d="M0 752h54v-83H0z"
        transform="translate(-27 -669)"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default RectangleShape;
