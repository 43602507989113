import { Image } from '../Image';
import { LinkedIn } from '../Icons';
import { RedactorContent } from '../redactor-content';
import styles from './uniker.module.scss';
import React from 'react';
import { Link } from '../Link';

export const Uniker = ({
  unikerDescription,
  unikerPhoto,
  unikerJobTitle,
  unikerLinkedIn,
  title,
}) => (
  <div className={styles['team-page-member']}>
    {unikerPhoto.map(({ id, url, width, height, focalPoint }) => (
      <Image
        className={styles['team-page-member-image']}
        key={id}
        alt={title}
        data-src={url}
        width={width}
        focalPoint={focalPoint}
        height={height}
      />
    ))}
    <div className={styles['team-page-member-name']}>
      <h1>{title}</h1>
      <Link href={unikerLinkedIn} target="_blank">
        <LinkedIn />
      </Link>
    </div>
    <h2 className={styles['team-page-member-title']}>{unikerJobTitle}</h2>
    <RedactorContent
      className={styles['team-page-member-description']}
      html={unikerDescription}
    />
  </div>
);
