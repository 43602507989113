import React from 'react';

import {CallToAction, CallToActionProps} from "./Buttons";
import {RedactorContent} from "./redactor-content";
import {Image} from "./Image";
// import '../styling/ExtraNews.scss';
import {SectionHeader} from "./Text";

type ExtraNewsProps = {
  id: string;
  title: string;
  subtitle: string;
  cta: [CallToActionProps];
  description: string;
  extraService?: [{ id: number; url: string; width: string | number; height: string | number; }];
}

export const ExtraNews = ({id, subtitle, title, cta, description, extraService}: ExtraNewsProps) => (
  <section id={id}>
    <div className="extra-news-container grid">
      <SectionHeader
        title={title}
        subtitle={subtitle}
      />
      {/*<h4 className="extra-news-subtitle">{subtitle}</h4>*/}
      {/*<h2 className="extra-news-title">{title}</h2>*/}
      <RedactorContent className="extra-news-description" html={description}/>
      {cta.map(cta => <CallToAction key={cta.id} {...cta} className="extra-news-cta"/>)}
      {extraService &&
      <div className="extra-news-special-wrapper">
        {extraService.map(service => <Image
          key={service.id}
          className="extra-news-special"
          alt="speciale actie"
          data-src={service.url}
          height={service.height}
          width={service.width}
        />)}
      </div>}
    </div>
  </section>
);
