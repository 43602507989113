import gql from 'graphql-tag';
import { GQLFunction } from '../../types';
import { GQL } from 'utils/graphql';
import { getLanguage } from 'utils/common';

export const GetContactQuery = gql`
  query getContactPage($lang: [QueryArgument]) {
    contact: entries(siteId: $lang, type: "contact") {
      ... on contact_contact_Entry {
        id
        title
        localized {
          uri
          language
        }
        contactDescription
        contactEmail
        contactPhone
        contactForm {
          ... on forms_forms_Entry {
            id
            title
            formDescription
            formFields {
              ... on formFields_formField_BlockType {
                id
                fieldType
                label
                required
              }
            }
            successTitle
            successCta {
              ... on callToAction_callToAction_Entry {
                id
                title
                ctaType
                ctaIcon
                ctaUrl
                ctaClass
                ctaTargetBlank
              }
            }
            successMessage
            failureMessage
          }
        }
        moodPhotos {
          id
          url @transform(width: 778, mode: "fit", immediately: true)
          width
          height
        }
        seoDescription
        seoImage {
          url
        }
        seoTitle
      }
    }
  }
`;

export const getContactPageData: GQLFunction = async (context, variables) => {
  const { data } = await GQL(
    GetContactQuery,
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};
