import React, {useEffect, useState} from "react";
import {CallToAction} from "./Buttons";
// import '../styling/cookies.scss';
import {RedactorContent} from "./redactor-content";

const cookiesKey = "cookieconsent_status";

const setCookie = (status) => {
  const d = new Date();
  d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
  const expires = "expires=" + d.toUTCString();
  document.cookie = "cookieconsent_status=" + status + ";" + expires + ";path=/";
};

const pushDataLayerCookie = (status) => {
  // @ts-ignore
  const dataLayer = window.dataLayer || [];
  setCookie(status);
  console.log(`sending gtm-event: ${status} ${Date.now().toString()}`);
  dataLayer.push({event: status});
};

const COOKIE_ACCEPTED = 'cookie_consent_allow';
const COOKIE_DENIED = 'cookie_consent_denied';

export const CookieBanner = ({cookieBannerData}: { cookieBannerData: any }) => {
  const banner = cookieBannerData.banner[0];
  const [isCookiePresent, setIsCookiePresent] = useState(true);

  useEffect(() => {
    setIsCookiePresent(document.cookie.includes(cookiesKey))
  }, [])

  if (typeof window === "undefined") {
    return (<></>);
  }
  const cookieConsentHandler = (status) => {
    pushDataLayerCookie(status);
    setIsCookiePresent(true);
  };

  return (isCookiePresent ? <></>
    :
    <div className={'cookie-container'}>
      <div className="text-box">
        <h1>{banner.title}</h1>
        <RedactorContent html={banner.cookieDescription}/>
      </div>
      <div className="btn-box">
        <CallToAction
          ctaType={banner.acceptCookies[0].ctaType}
          action={() => cookieConsentHandler(COOKIE_ACCEPTED)}
          title={banner.acceptCookies[0].title}
        />
        <CallToAction
          ctaType={banner.denyCookies[0].ctaType}
          action={() => cookieConsentHandler(COOKIE_DENIED)}
          title={banner.denyCookies[0].title}
        />
      </div>
    </div>);
};
