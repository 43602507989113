import React, {useLayoutEffect, useRef, useState} from "react";
import {RedactorContent} from "./redactor-content";

export const ContentBlock = (props: { title: string; content: string, type?: string }) => {

  const {title, content, type = "normal"} = props;
  return <section className={"content-block " + (type === 'quote' ? 'quote-block grid' : '')}>
    {type && type === 'normal' ? <h2>{title}</h2> : <blockquote className="quote">{title}</blockquote>}
    <RedactorContent html={content}/>
  </section>;
};

const toggleCollapse = (e) => {
  const content = e.target.nextElementSibling;
  const icon = e.target.getElementsByClassName("fas");
  if (icon && icon[0].classList.contains("fa-caret-right")) {
    icon[0].classList.add("fa-caret-down");
    icon[0].classList.remove("fa-caret-right");
  } else {
    icon[0].classList.remove("fa-caret-down");
    icon[0].classList.add("fa-caret-right");
  }
  if (content.style.maxHeight && content.style.maxHeight !== "0px") {
    content.style.maxHeight = "0px";
  } else {
    content.style.maxHeight = (content.scrollHeight + 50) + "px";
  }
};


export const CollapsibleContentBlock = (props: { title: string; content: string, expand?: boolean, enabled?: boolean }) => {
  const {title, content, expand, enabled} = props;
  const [open, setOpen] = useState(!!expand);
  const [height, setHeight] = useState("0");
  const ref = useRef();
  useLayoutEffect(() => {
    if (ref.current) {
      // @ts-ignore
      setHeight((ref.current.getElementsByClassName("redactor-block-content")[0].scrollHeight + 50) + "px");
    }
  }, []);
  if (typeof window !== "undefined") { // useLayoutEffect doesn't work on SSR which breaks the icon

    return (<section className="content-block" ref={ref}>
      <h2 onClick={() => enabled ? setOpen(!open) : ''}>{title}<i
        className={"fas fa-caret-right " + (open ? "open" : "")}/></h2>
      <RedactorContent html={content} style={{maxHeight: open ? height : '0'}} className="redactor-block-content"/>
    </section>);
  } else { // For SEO purposes
    return (<>
      <h2>{title}</h2>
      <RedactorContent html={content} className="redactor-block-content"/>
    </>);
  }
};
