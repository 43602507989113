import gql from 'graphql-tag';

export const GetCtaBannerQuery = gql`
  query getCareerOverview($lang: [QueryArgument]) {
    ctaBanner: entries(siteId: $lang, type: "ctaBanner") {
      ... on ctaBanner_ctaBanner_Entry {
        id
        title
        bannerTitle
        bannerDescription
        bannerColor
        bannerStyle
        bannerButton {
          ... on callToAction_callToAction_Entry {
            id
            title
            ctaType
            ctaUrl
          }
        }
      }
    }
  }
`;
