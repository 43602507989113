import React, {HTMLAttributes} from "react";
// import "../styling/redactor-content.scss";
import sanitizeHtml from "sanitize-html";

export {sanitizeHtml};

interface RedactorProps extends HTMLAttributes<HTMLDivElement> {
  html: string;
  ref?: any;
}

export const RedactorContent = ({html, ref, className, ...divProps}: RedactorProps) => (
  <div ref={ref} {...divProps} className={`redactor-content ${className || ""}`} dangerouslySetInnerHTML={{
    __html: sanitizeHtml(html || "", {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat(["h1", "h2", "del", "img", "a", "u", "i", "ol", "li", "strong"]),
      allowedAttributes: {
        a: ["href", "name", "target"],
        img: ["src","alt"],
        '*':["style"]
      },
      allowedSchemes: ["http", "https", "mailto", "tel"]
    })
  }}/>
);
