import gql from 'graphql-tag';
import { GQLFunction } from '../../types';
import { GQL } from 'utils/graphql';
import { getLanguage } from 'utils/common';
import { defaultVariables } from 'consts/default.consts';

export const GetTermsQuery = gql`
  query getCareerOverview($lang: [QueryArgument]) {
    termsConditions: entries(siteId: $lang, type: "termsConditions") {
      ... on termsConditions_termsConditions_Entry {
        id
        localized {
          uri
          language
        }
        seoTitle
        seoDescription
        seoImage {
          id
        }
        termBlock {
          ... on termBlock_termBlock_BlockType {
            id
            termsTitle
            termsDescription
          }
        }
      }
    }
  }
`;

export const getTermsPageData: GQLFunction = async (
  context,
  variables = defaultVariables
) => {
  const { data } = await GQL(
    GetTermsQuery,
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};
