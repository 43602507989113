import React from 'react';
import { ItemList, WithContext } from 'schema-dts';
import { sanitizeHtml } from './redactor-content';

export const CasesStructuredDataItemList = ({ cases }) => {
  const data: WithContext<ItemList> = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: cases.map((c, idx) => ({
      '@type': 'ListItem',
      position: idx + 1,
      url: 'https://unikoo.be/' + c.uri,
      name: c.title,
      description: c.description,
      image: c.caseHero[0]?.url,
    })),
  };
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(JSON.stringify(data)),
      }}
    />
  );
};
