import classNames from 'classnames';
import React, { FC } from 'react';

import { PrimaryLinkButton, RedactorContent, Image } from 'components';
import styles from './Teaser.module.scss';
import { CallToActionType, ImageType } from 'types/common';

type TeaserProps = {
  teaserTitle: string;
  teaserSubtitle: string;
  teaserDescription: string;
  teaserImage: ImageType[];
  callToAction: CallToActionType[];
  customCtaUrl: string;
};

export const Teaser: FC<TeaserProps> = ({
  teaserTitle,
  teaserDescription,
  teaserImage,
  teaserSubtitle,
  callToAction,
  customCtaUrl
}) => {
  return (
    <section className={styles['teaser-section']}>
      <div className={classNames('grid', styles['teaser-container'])}>
        <div className={styles['teaser-wrapper']}>
          <h4 className={styles['block-subtitle']}>{teaserSubtitle}</h4>
          <h2>{teaserTitle}</h2>
          <RedactorContent
            html={teaserDescription}
            className={styles['teaser-description']}
          />
          <div className={styles['action-footer']}>
            {callToAction &&
              callToAction.map(({ id, title, ctaUrl }) => (
                <PrimaryLinkButton
                  to={customCtaUrl ?? ctaUrl}
                  label={title}
                  key={id}
                />
              ))}
          </div>
        </div>
        <div className={styles['teaser-img-wrapper']}>
          {teaserImage?.map(({ id, url, width, height, focalPoint }) => (
              <Image
                key={id}
                className={styles.visual}
                width={width}
                height={height / 2}
                url={url}
                alt="calendar"
                focalPoint={focalPoint}
              />
            ))}
        </div>
      </div>
    </section>
  );
};
