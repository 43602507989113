import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Image } from 'components/Image';
// import '../styling/news-letter.scss';
import { Form } from 'components/Form';
import { sanitizeHtml } from 'components/redactor-content';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { getLanguage, verifyCaptcha } from 'utils/common';

const SubscribeMailchimp = async (event, mailchimpId, language) => {
  try {
    const data = Object.values(event);
    const result = await fetch(`/api/newsletter`, {
      method: 'POST',
      headers: {
        Origin: 'localhost:3000',
      },
      body: JSON.stringify({
        firstName: data[2],
        lastName: data[3],
        email: data[4],
        mailchimpId,
        language,
      }),
    });
    return result.status;
  } catch (e) {
    console.error(e);
    return 400;
  }
};

export const NewsLetter = ({
  newsLetterData,
  commonData,
}: {
  newsLetterData: any;
  commonData: any;
}) => {
  const router = useRouter();
  const [status, setStatus] = useState<number>();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formData = newsLetterData.form[0];

  return (
    <section id="newsletter">
      <div className="grid">
        <Image
          alt="newsletter"
          data-src="/newsletter.svg"
          height={184}
          width={219}
        />
        <Form
          form={formData}
          action={async (ev) => {
            /*if (!executeRecaptcha) {
              return;
            }
            const token = await executeRecaptcha('REGISTER_NEWSLETTER');

            const { score } = await verifyCaptcha(token);*/
            if (!isSubmitted /* && score >= 0.3*/) {
              setIsSubmitting(true);
              const newStatus = await SubscribeMailchimp(
                ev,
                commonData.common[0]?.mailchimpAudienceId,
                router.locale
              );
              setStatus(newStatus);
              setIsSubmitted(true);
              setIsSubmitting(false);
            }
          }}
          submitDisabled={isSubmitting}
          spinnerProps={{
            spinnerCss: 'margin: 0 36px;',
            spinnerSize: 20,
          }}
        />
        {status === 200 && (
          <div className="success">
            <img
              src="/alt-task-check.svg"
              alt={'Submission successful'}
              height={25}
              width={25}
              className={'submission-success'}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(formData.successMessage),
              }}
            />
          </div>
        )}
        {status !== 200 && isSubmitted && (
          <div
            className="fail"
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(formData.failureMessage),
            }}
          />
        )}
      </div>
    </section>
  );
};

export default NewsLetter;
