import { useMemo } from 'react';
import { useRouter } from 'next/router';
// import "styling/team-services.scss";
import { CallToAction } from 'components/Buttons';
import { FullWidthBlock } from 'components/FullWidthBlock';
import { getTeamData } from 'utils/teamData';

export const TeamServices = (props: {
  data: any[];
  readMoreButton: any[];
  slug: string;
  teamStaffing?: any[];
}) => {
  const router = useRouter();

  const background = useMemo(() => {
    const teamData = getTeamData(props.slug);
    if (teamData.bg) {
      return `url(/assets/bg/${teamData.bg}) no-repeat center`;
    }
    return undefined;
  }, [props.slug]);

  const teamColor = useMemo(() => {
    const { color } = getTeamData(props.slug);
    return color;
  }, [props.slug]);

  return (
    <section
      className="team-services"
      style={{
        background,
        backgroundSize: 'cover',
      }}
    >
      <div className="grid">
        {props.data.map((teamService) => (
          <div
            key={teamService.id}
            className="team-service"
            itemScope
            itemType="http://schema.org/Service"
          >
            <h2 className="team-service-name" itemProp="serviceType">
              {teamService.title}
            </h2>
            <hr
              className="team-service-divider"
              style={{ backgroundColor: teamColor }}
            />
            <p className="team-service-description" itemProp="slogan">
              {teamService.shortDescription}
            </p>
            {props.readMoreButton[0] && (
              <CallToAction
                ctaType={props.readMoreButton[0].ctaType}
                title={props.readMoreButton[0].title}
                ctaUrl={`/${router.locale}/services/${props.slug}/${teamService.slug}`}
              />
            )}
          </div>
        ))}

        {!!props?.teamStaffing?.length &&
          props.teamStaffing.map(({ id, header, description, image }) => (
            <FullWidthBlock
              key={id}
              id={id}
              header={header}
              description={description}
              image={image}
              className="team-staffing"
            />
          ))}
      </div>
    </section>
  );
};
