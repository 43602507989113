import gql from 'graphql-tag';
import { GQLFunction } from '../../types';
import { GQL } from 'utils/graphql';
import { getLanguage, isProduction } from 'utils/common';
import { defaultVariables } from 'consts/default.consts';

export const SpecialtyDetailQuery = gql`query getSpecialtyDetail($lang: [QueryArgument], $slug: [String]) {
  specialtyPost: entry(siteId: $lang, slug: $slug, type: "specialty", status: ${
    !isProduction() ? '["disabled", "pending","expired","live"]' : '["live"]'
  }) {
    id
    slug
    title
    localized {
      uri
      language
    }
    ... on services_specialty_Entry {
      id
      seoTitle
      seoDescription
      seoImage {
        url
      }
      specialtyTeam {
        ... on team_team_Entry {
          id
          title
          teamUrl
          teamAnimation {
            url
          }
          teamLogo {
            id
            url
            width
            height
            focalPoint
          }
        }
      }
      contentBlocks {
        ... on contentBlocks_contentBlock_BlockType {
          id
          blockType
          contentTitle
          blockContent
        }
      }
    }
  }
  common: entries(siteId: $lang, type: "careerDetail") {
    ... on careerDetail_careerDetail_Entry {
      id
      backCta {
        ... on callToAction_callToAction_Entry {
          id
          title
          ctaType
          ctaIcon
          ctaUrl
          ctaClass
          ctaTargetBlank
        }
      }
    }
  }
}`;

export const getAllSpecialtiesQuery = gql`
  query getAllTeamSpecialties($lang: [QueryArgument]) {
    entries(siteId: $lang, type: "specialty") {
      id
      title
      slug
      parent {
        slug
      }
      ... on services_specialty_Entry {
        id
        specialtyTeam {
          ... on team_team_Entry {
            id
            slug
          }
        }
      }
    }
  }
`;

export const getSpecialtyPageData: GQLFunction = async (context, variables) => {
  const { data } = await GQL(
    SpecialtyDetailQuery,
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};

export const getAllSpecialtyPages: GQLFunction = async (
  context,
  variables = defaultVariables
) => {
  const { data } = await GQL(
    getAllSpecialtiesQuery,
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};
