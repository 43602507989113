import styles from './job-block.module.scss';
import { Image } from 'components/Image';
import { CallToAction, ctaType } from 'components/Buttons';
import { RedactorContent } from 'components/redactor-content';
import Tag from 'components/Tag/Tag';
import { TagTypes } from 'components/Tag/types';

export const JobBlock = (props: {
  team: any;
  jobTitle: string;
  description: string;
  location?: { city: string; title: string }[];
  jobLink: string;
  jobCTA: { title: string; ctaType: ctaType };
}) => (
  <div className={styles.jobBlock}>
    <Image
      alt=""
      data-src={props.team.teamHero[0].logo[0].url}
      width={props.team.teamHero[0].logo[0].width}
      height={props.team.teamHero[0].logo[0].height}
    />

    <div className={styles.jobHeader}>
      <h1 className={styles.jobTitle}>{props.jobTitle}</h1>
    </div>

    <div className={styles.locationTags}>
      {props.location.map((location) => (
        <Tag key={location.city} text={location.title} type={TagTypes.GRAY} />
      ))}
    </div>

    <RedactorContent className={styles.jobIntro} html={props.description} />

    <div className={styles.jobFooter}>
      <CallToAction
        className={styles.button}
        ctaType={props.jobCTA.ctaType}
        title={props.jobCTA.title}
        ctaUrl={props.jobLink}
        target={true}
      />
    </div>
  </div>
);
