import { Image } from 'components/Image';
import { RedactorContent } from 'components/redactor-content';

export const FullWidthBlock = (props: {
  id: string;
  image: any[];
  header: string;
  description: string;
  className: string;
}) => (
  <div className={`full-width-block ${props.className}`}>
    {props?.image?.[0]?.url && (
      <Image
        url={props.image[0].url}
        width={props.image[0].width}
        height={props.image[0].height}
        alt={props.image[0].title}
        className={`block-image ${props.className}-image`}
      />
    )}
    <div className={`block-content ${props.className}-content`}>
      <h2 className={`block-content-header ${props.className}-header`}>
        {props.header}
      </h2>
      <RedactorContent
        html={props.description}
        className={`block-content-description ${props.className}-description`}
      />
    </div>
  </div>
);
