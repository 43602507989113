import * as React from 'react';
import { ImgHTMLAttributes } from 'react';

interface IProps extends ImgHTMLAttributes<HTMLImageElement> {
  alt: string;
  height: string | number;
  width: string | number;
  url?: string;
  focalPoint?: number[];
  ['data-src']?: string;
}

export const Image = ({
  width,
  height,
  url,
  className,
  alt,
  focalPoint,
  style,
  ...props
}: IProps) => (
  <img
    width={typeof width === 'number' ? width + 'px' : width}
    height={typeof height === 'number' ? height + 'px' : height}
    className={`lozad ${className ? className : ''}`}
    style={{
      ...style,
      objectPosition: focalPoint
        ? `${focalPoint.map((el) => Number(el) * 100 + '%').join(' ')}`
        : '',
    }}
    alt={alt}
    data-src={url}
    {...props}
    decoding={'async'}
    loading="lazy"
  />
);
