import gql from 'graphql-tag';
import { GQLFunction } from '../../types';
import { GQL } from 'utils/graphql';
import { getLanguage } from 'utils/common';

export const GetForm = gql`
  query getForm($lang: [QueryArgument], $slug: [String]) {
    form: entries(siteId: $lang, type: "forms", slug: $slug) {
      ... on forms_forms_Entry {
        id
        title
        formDescription
        formFields {
          ... on formFields_formField_BlockType {
            id
            fieldType
            label
            actionLabel
            required
          }
        }
        successMessage
        failureMessage
      }
    }
  }
`;

export const getNewsLetterData: GQLFunction = async (context, variables) => {
  const { data } = await GQL(
    GetForm,
    { ...variables, lang: getLanguage(context), slug: 'newsletter' },
    context
  );
  return data;
};
