import { useRouter } from 'next/router';
import React from 'react';
import styles from './nav-comp.module.scss';
import { strings } from '../../consts/translations';

export const NavComp = () => {
  const router = useRouter();
  return (
    <div className={styles['case-nav-link-wrapper']}>
      <div onClick={router.back} className={styles['case-nav-link']}>
        <h2 className={styles['case-nav-link-label']}>
          <i className="fal fa-arrow-left" /> {strings[router.locale].back}
        </h2>
      </div>
      {/*<div className="horizontal-divider"/>*/}
      {/*<Link href={'/cases'} className="case-nav-link">*/}
      {/*<h2 className="case-nav-link-label">Naar alle cases<i className="fal fa-arrow-right"/></h2>*/}
      {/*</Link>*/}
    </div>
  );
};
