import gql from 'graphql-tag';
import { GQLFunction } from '../../types';
import { GQL } from 'utils/graphql';
import { getLanguage } from 'utils/common';

export const ServiceDetailQuery = gql`
  query getServiceDetail($slug: [String], $lang: [QueryArgument]) {
    entry(siteId: $lang, slug: $slug, type: "services") {
      id
      slug
      title
      localized {
        uri
        language
      }
      ... on services_services_Entry {
        id
        teamHero {
          ... on teamHero_hero_BlockType {
            id
            serviceDescription
            serviceTitle
            teamName
            logo {
              url
              width
              height
              focalPoint
            }
            animation {
              url
            }
            nextService {
              ... on callToAction_callToAction_Entry {
                id
                title
                ctaUrl
                ctaType
                ctaIcon
                ctaClass
              }
            }
            previousService {
              ... on callToAction_callToAction_Entry {
                id
                title
                ctaUrl
                ctaType
                ctaIcon
                ctaClass
              }
            }
            serviceCallToAction {
              ... on callToAction_callToAction_Entry {
                id
                title
                ctaUrl
                ctaType
                ctaIcon
              }
            }
          }
        }
        ctaBanner {
          ... on ctaBanner_ctaBanner_Entry {
            id
            title
            bannerTitle
            bannerDescription
            bannerStyle
            bannerButton {
              ... on callToAction_callToAction_Entry {
                id
                title
                ctaType
                ctaUrl
              }
            }
          }
        }
        specialtyReadMore {
          ... on callToAction_callToAction_Entry {
            title
            ctaType
            ctaIcon
            ctaUrl
            ctaClass
            ctaTargetBlank
          }
        }
        teamServices {
          ... on teamServices_teamService_BlockType {
            id
            teamServiceTitle
            teamServiceDescription
            teamServiceUrl
            readMoreButton {
              ... on callToAction_callToAction_Entry {
                id
                title
                ctaType
                ctaClass
              }
            }
          }
        }
        extraService {
          ... on extraService_extraService_BlockType {
            id
            heading
            subheading
            description
            extraServiceCta {
              ... on callToAction_callToAction_Entry {
                id
                title
                ctaType
                ctaUrl
              }
            }
            images {
              id
              url
              width
              height
              focalPoint
              title
            }
          }
        }
        teamStaffing {
          ... on teamStaffing_teamStaffing_BlockType {
            id
            header
            description
            image {
              url @transform(width: 778, mode: "fit", immediately: true)
              width
              height
              focalPoint
              title
            }
          }
        }
        teamDescription {
          ... on teamDescription_teamDescription_BlockType {
            id
            teamDescriptionTitle
            teamDescriptionSubtitle
            teamDescriptionText
          }
        }
        teamMembers {
          ... on uniker_uniker_Entry {
            id
            unikerJobTitle
            title
            unikerPhoto {
              url @transform(width: 778, mode: "fit", immediately: true)
              width
              height
              focalPoint
            }
          }
        }
        teamProjectCta {
          ... on callToAction_callToAction_Entry {
            id
            ctaType
            title
          }
        }
        teamProjectDescription {
          ... on teamProjectDescription_teamProjects_BlockType {
            id
            teamProjectTitle
            teamProjectSubtitle
          }
        }
        processBlock {
          ... on processBlock_processBlock_BlockType {
            id
            processTitle
            processSubtitle
          }
        }
        processSteps {
          ... on processSteps_processstep_BlockType {
            id
            stepTitle
            stepDescription
            sortOrder
          }
        }
      }
      specialties: children(siteId: $lang, type: "specialty") {
        id
        slug
        title
        ... on services_specialty_Entry {
          id
          shortDescription
        }
      }
    }
  }
`;

export const getServiceDetailData: GQLFunction = async (context, variables) => {
  const { data } = await GQL(
    ServiceDetailQuery,
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};
