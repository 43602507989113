import React, { FC } from 'react';
import styles from './Tag.module.scss';
import { TagProps, TagTypes } from './types';

const Tag: FC<TagProps> = ({ className, text, type }) => {
  return (
    <span
      className={`${styles.tag} ${type ? styles[type] : ''} ${className ?? ''}`}
    >
      {text}
    </span>
  );
};

Tag.defaultProps = {
  type: TagTypes.REGULAR,
};

export default Tag;
