import React from 'react';
import { CallToAction } from './Buttons';
import { Quote } from './Text';
// import "styling/more-cases.scss";^
import { RedactorContent } from './redactor-content';

export const CTABanner = ({
  bannerStyle,
  bannerTitle,
  bannerDescription,
  bannerButton,
  bannerExtraClasses,
}) => (
  <div
    className={`more-cases ${bannerStyle ? bannerStyle : ''} ${
      bannerExtraClasses ? bannerExtraClasses : ''
    }`}
  >
    <div className="grid">
      <Quote>{bannerTitle}</Quote>
      <RedactorContent
        className="more-cases-content"
        html={bannerDescription}
      />
      {!!bannerButton.length && (
        <CallToAction
          ctaType={bannerButton[0].ctaType}
          ctaUrl={bannerButton[0].ctaUrl}
          title={bannerButton[0].title}
          target={bannerButton[0].ctaTargetBlank}
        />
      )}
    </div>
  </div>
);
