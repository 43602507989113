import gql from 'graphql-tag';
import { GQLFunction } from '../../types';
import { GQL } from 'utils/graphql';
import { getLanguage, isProduction } from 'utils/common';

export const BlogOverviewQuery = gql`
  query getBlogOverview($lang: [QueryArgument]) {
    common: entry(siteId: $lang, type: "blogOverview") {
      ... on blogOverview_blogOverview_Entry {
        id
        localized {
          uri
          language
        }
        mainBlog {
          ... on blogPosts_blogPosts_Entry {
            id
            title
            blogCategory {
              ... on blogType_Category {
                id
                title
              }
            }
            blogService {
              ... on services_services_Entry {
                id
                title
              }
            }
            url
            uri
            blogDescription
            blogImage {
              url @transform(height: 500, mode: "fit", immediately: true)
              width
              height
              focalPoint
            }
          }
        }
        mainBlogCta {
          ... on callToAction_callToAction_Entry {
            id
            title
            ctaType
            ctaClass
            ctaIcon
            ctaTargetBlank
            ctaUrl
          }
        }
        cta {
          ... on callToAction_callToAction_Entry {
            id
            title
            ctaType
            ctaClass
            ctaIcon
            ctaTargetBlank
            ctaUrl
          }
        }
        showAllText
        seoDescription
        seoTitle
        seoImage {
          url @transform(height: 500, mode: "fit", immediately: true)
          width
          height
          focalPoint
        }
      }
    }
  }
`;

export const getBlogOverviewPageData: GQLFunction = async (
  context,
  variables
) => {
  const { data } = await GQL(
    BlogOverviewQuery,
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};

export const AllBlogsQuery = gql`
query getBlogOverview($lang: [QueryArgument], $offset: Int, $limit: Int, $categoryIds: [QueryArgument], $groupId: [QueryArgument]) {
  total:entries(siteId: $lang, type: "blogPosts", relatedTo:$categoryIds, status: ${
    !isProduction() ? '["disabled", "pending","expired","live"]' : '["live"]'
  }) {id}
  blogs: entries(siteId: $lang, type: "blogPosts", offset: $offset, limit: $limit, relatedTo: $categoryIds, orderBy: "postDate", inReverse: true, status: ${
    !isProduction() ? '["disabled", "pending","expired","live"]' : '["live"]'
  }) {
    ... on blogPosts_blogPosts_Entry {
      id
      slug
      title
      postDate
      uri
      localized {
        slug
        language
        siteId
      }
      blogCategory {
        ... on blogType_Category {
          id
          title
          shortName
        }
      }
      blogService {
        ... on services_services_Entry {
          id
          title
          teamHero {
            ... on teamHero_hero_BlockType {
              id
              logo {
                id
                url
                height
                width
              }
            }
          }
        }
      }
      url
      blogDescription
      blogImage {
        id
        url @transform (height:500, mode: "fit" immediately: true)
        width
        height
      }
    }
  }
  categories: categories(siteId: $lang, groupId: $groupId) {
    title
    id
    slug
  }
  loadMore: entries(siteId: $lang, type: "callToAction", slug: "meer-laden") {
    id
    title
  }
  cta: entry(siteId: $lang, type: "blogOverview") {
    ... on blogOverview_blogOverview_Entry {
      id
      cta {
        ... on callToAction_callToAction_Entry {
          id
          title
          ctaType
          ctaClass
          ctaIcon
          ctaTargetBlank
          ctaUrl
        }
      }
    }
  }
}
`;

export const getAllBlogsData: GQLFunction = async (context, variables) => {
  const { data } = await GQL(
    AllBlogsQuery,
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};
