import gql from 'graphql-tag';
import { GQLFunction } from '../../types';
import { GQL } from 'utils/graphql';
import { getLanguage } from 'utils/common';

export const GetCookieBannerQuery = gql`
  query getCookieBanner($lang: [QueryArgument]) {
    banner: entries(siteId: $lang, type: "cookieBanner") {
      ... on cookieBanner_cookieBanner_Entry {
        id
        title
        cookieDescription
        acceptCookies {
          ... on callToAction_callToAction_Entry {
            id
            title
            ctaType
          }
        }
        denyCookies {
          ... on callToAction_callToAction_Entry {
            id
            title
            ctaType
          }
        }
      }
    }
  }
`;

export const getCookieBannerData: GQLFunction = async (context, variables) => {
  const { data } = await GQL(
    GetCookieBannerQuery,
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};
