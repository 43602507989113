import React from 'react';
import { Image } from './Image';
import { CallToAction, ctaType } from './Buttons';
import { useRouter } from 'next/router';
import ArchShape from 'assets/svg/arch-shape';
import TrapeziumShape from 'assets/svg/trapezium-shape';
import RectangleShape from 'assets/svg/rectangle-shape';
import { teamColors, TeamName } from 'consts/common.consts';
// import '../styling/project.scss';

export type Tag = {
  title: string;
  teamHero: any[];
};
export type ProjectProps = {
  tags: Tag[];
  title: string;
  description: string;
  imageSrc: string;
  uri: string;
  ctaTitle: string;
  ctaType: ctaType;
};
export const Project = (props: ProjectProps) => {
  const router = useRouter();
  return (
    <div className="project">
      <div
        className="banner lozad"
        data-background-image={props.imageSrc}
        onClick={() => router.push(props.uri)}
      >
        <ArchShape
          fillColor={teamColors[TeamName.DES]}
          className="shapes top-left"
        />
        <ArchShape
          fillColor={teamColors[TeamName.MAR]}
          className="shapes center"
        />
        <TrapeziumShape
          fillColor={teamColors[TeamName.FIN]}
          className="shapes top-right"
        />
        <RectangleShape
          fillColor={teamColors[TeamName.DEV]}
          className="shapes bottom-left"
        />
        <TrapeziumShape
          fillColor={teamColors[TeamName.REC]}
          className="shapes bottom-right"
        />
        <div className="tags">
          {props.tags.map((tag: Tag) => (
            <div key={tag.title} className="tag">
              <Image
                alt=""
                data-src={`${tag.teamHero[0].logo[0].url}`}
                width='40px'
                height='30px'
              />
              <h1>{tag.title}</h1>
            </div>
          ))}
        </div>
      </div>
      <h3 className="project-title">{props.title}</h3>
      <h1 className="project-description">{props.description}</h1>
      <CallToAction
        ctaUrl={props.uri}
        title={props.ctaTitle}
        ctaType={props.ctaType}
      />
    </div>
  );
};
