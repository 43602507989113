import gql from 'graphql-tag';
import { GQLFunction } from '../../types';
import { GQL } from 'utils/graphql';
import { getLanguage } from 'utils/common';

export const ServerErrorQuery = gql`
  query getServerError($lang: [QueryArgument]) {
    entry(siteId: $lang, type: "ServerError") {
      ... on serverError_serverError_Entry {
        id
        title
        localized {
          uri
          language
        }
        serverError {
          ... on serverError_serverErrorBlock_BlockType {
            id
            heading
            description
            back {
              id
              ... on callToAction_callToAction_Entry {
                id
                ctaType
                title
                ctaUrl
              }
            }
            extrabutton {
              id
              ... on callToAction_callToAction_Entry {
                id
                ctaType
                title
                ctaUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const getServerErrorPageData: GQLFunction = async (
  context,
  variables
) => {
  const { data } = await GQL(
    ServerErrorQuery,
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};
