export type TagProps = {
  className?: string;
  type?: TagTypes;
  text: string;
};

export enum TagTypes {
  REGULAR = 'regular',
  GRAY = 'gray',
  WHITE = 'white'
}
