import { teamColors, TeamName } from 'consts/common.consts';

export const getTeamData: (
  slug
) => {
  square: string;
  outline: string;
  bg?: string;
  pic: string;
  class?: string;
  color: string;
} = (slug) => {
  switch (slug) {
    case 'technology-development':
      return {
        square: '/assets/icon/Tech-square.svg',
        outline: '/assets/icon/Tech-outline.svg',
        pic: '/assets/icon/Tech-pic.png',
        class: 'dev',
        bg: 'bgshapes-dev.svg',
        color: teamColors[TeamName.DEV],
      };
    case 'marketing-communicatie':
    case 'marketing-communication':
      return {
        square: '/assets/icon/Marketing-square.svg',
        outline: '/assets/icon/Marketing-outline.svg',
        pic: '/assets/icon/Marketing-pic.png',
        class: 'mark',
        bg: 'bgshapes-marketing.svg',
        color: teamColors[TeamName.MAR],
      };
    case 'recruitment-talentbeheer':
    case 'talent-mgmt-recruitment':
      return {
        square: '/assets/icon/HR-square.svg',
        outline: '/assets/icon/HR-outline.svg',
        pic: '/assets/icon/HR-pic.png',
        class: 'hr',
        bg: 'bgshapes-hr.svg',
        color: teamColors[TeamName.REC],
      };
    case 'graphic-design':
    case 'graphic-digital-design':
      return {
        square: '/assets/icon/Design-square.svg',
        outline: '/assets/icon/Design-outline.svg',
        pic: '/assets/icon/Design-pic.png',
        class: 'design',
        bg: 'bgshapes-design.svg',
        color: teamColors[TeamName.DES],
      };
    case 'finance-administratie':
    case 'finance-administration':
      return {
        square: '/assets/icon/Admin-square.svg',
        outline: '/assets/icon/Admin-outline.svg',
        pic: '/assets/icon/Admin-pic.png',
        class: 'admin',
        bg: 'bgshapes-admin.svg',
        color: teamColors[TeamName.FIN],
      };
    default:
      return {
        square: '',
        outline: '',
        pic: '',
        color: '#0E1035',
      };
  }
};
