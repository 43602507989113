import moment from 'moment';
import 'moment/locale/nl';
import 'moment/locale/en-gb';

export const formatDate = (dateString: string, siteId?: string): string => {
  moment.locale(siteId);
  return moment(dateString).format('LL');
};
export const formatDateNumber = (dateString: string, siteId?: string) => {
  moment.locale(siteId);
  return moment(dateString).format('L');
};
