import { useEffect, useMemo, useState } from 'react';
// import '../styling/caselist.scss';
// import '../styling/filters.scss';
import { CallToAction, CallToActionProps, ctaType } from 'components/Buttons';
import { Image } from 'components/Image';
import { Link } from 'components/Link';
import { lozadObserver } from 'pages/_app';
import { CasesStructuredDataItemList } from 'components/micro-data-cases';
import { RedactorContent } from 'components/redactor-content';
import { getAllCasesData } from 'queries';
import { useRouter } from 'next/router';
import { getTeamData } from 'utils/teamData';
import {strings} from 'consts/translations'
import { unikooDefaultColor } from 'consts/common.consts';

const fetchMore = async ({ variables, updateQuery }) => {
  const { cases } = await getAllCasesData(null, variables);
  updateQuery(cases);
};

export const CaseList = ({
  cta,
  definedTeam = '',
  hideFilters = false,
  hideLoadMore = false,
  allCasesData,
}: {
  cta?: CallToActionProps;
  definedTeam?: string;
  hideFilters?: boolean;
  hideLoadMore?: boolean;
  allCasesData: any;
}) => {
  const router = useRouter();
  const [filter, setFilter] = useState([]);
  useEffect(() => lozadObserver.observe);

  const {
    cases,
    teams,
    total: { length: totalCount },
    loadMore,
  } = allCasesData;
  const [showcases, setShowcases] = useState(cases);

  let teamIds;
  if (definedTeam) {
    teamIds = [definedTeam];
  } else {
    teamIds = teams.map((team) => team.id);
  }

  if (filter.length === 0) {
    setFilter(teamIds);
  }

  const showAll = useMemo(
    () => JSON.stringify(teamIds) === JSON.stringify(filter),
    [teamIds, filter]
  );
  
  const handleFilter = (val) => {
    setFilter(val)

    const filteredCases = val.length === 1 
    ? cases.filter(c => {
      return c.caseServices.map(t => t.id).includes(val[0])
      }) 
    : cases

    setShowcases(filteredCases)
  }

  return (
    <section id="caselist">
      {!hideFilters && (
        <div className="filters">
          <p className="filter-header">Filter op categorie</p>
          {teams.map(({ title, id, slug }) => (
            <div
              key={`filter-${title}-${id}`}
              onClick={() => handleFilter([id])}
              className="filter"
            >
              <p
                className={`filter-item ${
                  filter.length === 1 && filter[0] === id ? 'selected' : ''
                } ${filter[0] + id}`}
                style={{
                  color:
                    !showAll && filter[0] === id
                      ? getTeamData(slug).color
                      : unikooDefaultColor,
                }}
              >
                {title}
              </p>
            </div>
          ))}
          <div className="divider" />
          <div onClick={() => handleFilter(teamIds)} className="filter">
            <p
              className={`filter-item filter-all ${showAll ? 'selected' : ''}`}
            >
              {strings[router.locale].showAll}
            </p>
          </div>
        </div>
      )}
      <CasesStructuredDataItemList cases={showcases} />
      {showcases.length > 0 && (
        <div className="grid">
          {showcases.map((entry) => (
            <Link
              key={entry.id}
              href={`/${router.locale}/cases/${entry.slug}`}
              className="lozad"
              data-background-image={entry.caseHero[0].url}
            >
              <div className={`case-detail-banner`}>
                <div className="case-detail-banner-text">
                  <h1 className="case-detail-banner-title">{entry.title}</h1>
                  <RedactorContent
                    className="case-detail-banner-description"
                    html={entry.caseIntro}
                  />
                  {cta && <span>{cta.title}</span>}
                  {/*<CallToAction ctaType={cta.ctaType} title={cta.title} ctaUrl={entry.uri}/>*/}
                  <div className="case-detail-banner-tags">
                    {entry.caseServices.map((team) => (
                      <Image
                        key={`case-${entry.id}-team-${team.id}`}
                        alt={team.title}
                        data-src={team.teamHero[0].logo[0].url}
                        src={team.teamHero[0].logo[0].url}
                        height={team.teamHero[0].logo[0].height}
                        width={team.teamHero[0].logo[0].width}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
      {!hideLoadMore && (
        <CallToAction
          className={`${showcases.length < totalCount ? 'show' : 'hide'}`}
          action={() =>
            fetchMore({
              variables: {
                offset: showcases.length,
                limit: 5,
                lang: router.locale === 'en' ? 2 : 1,
                teamIds,
              },
              updateQuery: (extraCases) => {
                setShowcases([...showcases, ...extraCases]);
              },
            })
          }
          title={loadMore[0].title}
          ctaType={ctaType.plainText}
        />
      )}
    </section>
  );
};
