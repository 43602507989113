import React from "react";
// import '../styling/parallax.scss';
// import {Image} from "./Image";
// import {useWindowSize} from "../hooks";

export const ParallaxBackground = ({top, height}: { top?: string; height?: string }) => {

  // const {width} = useWindowSize();
  // const isMobile = width <= 778;

  // const imageOffsets = [
  //   {
  //     y: [(isMobile ? -50 : -100), (isMobile ? 400 : 400)],
  //     x: [(isMobile ? -15 : 50), (isMobile ? -15 : -5)]
  //   },
  //   {
  //     y: [(isMobile ? -50 : -300), 800],
  //     x: [(isMobile ? 95 : 85), (isMobile ? 95 : 85)]
  //   },
  //   {
  //     y: [(isMobile ? 50 : 200), (isMobile ? 600 : 150)],
  //     x: [(isMobile ? 40 : 90), (isMobile ? 0 : 90)]
  //   },
  // ];
  return (
    // <ParallaxProvider>
    <div className="parallax-background" style={{top: top ? top : '40vh', height: height ? height : '100vh'}}>
      <div className="parallax-moving">
        {/*<BGShapesV2/>*/}

        {/*<Image style={{*/}
        {/*  right: '100px',*/}
        {/*  transform: 'rotate(-80deg)',*/}
        {/*  height: '220px',*/}
        {/*  bottom: '230px'*/}
        {/*}} data-src="/shapes/circle.svg" src="/shapes/circle.svg" alt=""/>*/}
        {/*<Image style={{left: '115px', top: '-120px', height: '96px'}} data-src="/shapes/Fill9.svg"*/}
        {/*       src="/shapes/Fill9.svg" alt=""/>*/}
        {/*<Image style={{*/}
        {/*  left: '125px',*/}
        {/*  transform: 'rotate(150deg)',*/}
        {/*  height: '220px',*/}
        {/*  bottom: '-130px',*/}
        {/*}} data-src="/shapes/circle.svg" src="/shapes/circle.svg" alt=""/>*/}
      </div>
    </div>
    // </ParallaxProvider>
  );
};
