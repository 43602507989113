import gql from 'graphql-tag';
import { GQLFunction } from '../../types';
import { GQL } from 'utils/graphql';
import { getLanguage, isProduction } from 'utils/common';

export const CareerDetailQuery = () => gql`
query getCareerOverview($lang: [QueryArgument], $slug: [String]) {
  careerDetail: entries(siteId: $lang, type: "careerDetail") {
    ... on careerDetail_careerDetail_Entry {
      id
      dateCreated
      localized {
        uri
        language
      }
      applyCta {
        ... on callToAction_callToAction_Entry {
          id
          title
          ctaType
          ctaIcon
          ctaUrl
          ctaClass
          ctaTargetBlank
        }
      }
      backCta {
        ... on callToAction_callToAction_Entry {
          id
          title
          ctaType
          ctaIcon
          ctaUrl
          ctaClass
          ctaTargetBlank
        }
      }
      pageForm {
        ... on forms_forms_Entry {
          id
          title
          formDescription
          formFields {
            ... on formFields_formField_BlockType {
              id
              fieldType
              label
              actionLabel
              required
            }
          }
          successTitle
          successMessage
          successCta {
            ... on callToAction_callToAction_Entry {
              id
              title
              ctaType
              ctaIcon
              ctaUrl
              ctaClass
              ctaTargetBlank
            }
          }
          failureMessage
        }
      }
      applySpontaneousCta {
        ... on ctaBanner_ctaBanner_Entry {
          bannerTitle
          bannerButton {
            ... on callToAction_callToAction_Entry {
              id
              title
              ctaType
              ctaIcon
              ctaUrl
              ctaClass
              ctaTargetBlank
            }
          }
          bannerColor
          bannerDescription
          bannerExtraClasses
          bannerStyle
        }
      }
    }
  }
  jobOffer: entries(siteId: $lang, type: "jobOffers", slug: $slug, status:${
    !isProduction() ? '["disabled", "pending", "expired", "live"]' : '["live"]'
  }) {
    id
    localized {
      uri
      language
    }
    ... on jobOffers_jobOffers_Entry {
      id
      title
      jobOfferDescription
      location {
        ... on locaties_locaties_Entry {
          id
          title
          straatHuisnummer
          stad
          postcode
          provincie
          land
        }
      }
      jobOfferService {
        ... on services_services_Entry {
          id
          title
          teamHero {
            ... on teamHero_hero_BlockType {
              id
              logo {
                url
                width
                height
              }
            }
          }
        }
      }
      contentBlocks {
        ... on contentBlocks_contentBlock_BlockType {
          id
          contentTitle
          blockContent
        }
      }
      seoImage {
        url
      }
      seoTitle
      seoDescription
    }
  }
}
`;

export const getCareerDetailPageData: GQLFunction = async (
  context,
  variables
) => {
  const { data } = await GQL(
    CareerDetailQuery(),
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};
