import { FC } from 'react';
import { ShapeProps } from './types';

const TrapeziumShape: FC<ShapeProps> = ({ fillColor, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 39 23"
      className={className || ''}
    >
      <path
        d="m0 19.95 17.728 2.903 20.421-19.57L21.187.506z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default TrapeziumShape;
