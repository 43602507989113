import React, {useState} from "react";


export const SocialButtons = ({uri}: {uri:string}) => {

  const [copied, setCopied] = useState('');
  return (<div className="social-links grid">
    <a target="popup"
       onClick={() => window.open("https://www.facebook.com/sharer/sharer.php?u=https://unikoo.be/" + uri, 'popup', 'width=600,height=600')}><i
      className="fab fa-facebook"/></a>
    <a target="popup"
       onClick={() => window.open("http://www.linkedin.com/shareArticle?mini=true&url=https://unikoo.be/" + uri, 'popup', 'width=600,height=600')}><i
      className="fab fa-linkedin"/></a>
    <a target="popup"
       onClick={() => window.open("https://twitter.com/share?url=https://unikoo.be/" + uri, 'popup', 'width=600,height=600')}><i
      className="fab fa-twitter"/></a>
    <a target="popup" onClick={() => {
      const el = document.createElement('textarea');
      el.value = "https://unikoo.be/" + uri;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      setCopied('copied');
      setTimeout(()=> setCopied(''), 2500);
    }} className={copied}><i className="fas fa-link"/></a>
  </div>);

};
