import {
  ApolloClient,
  ApolloQueryResult,
  defaultDataIdFromObject,
  InMemoryCache,
  NormalizedCacheObject,
  OperationVariables,
  QueryOptions
} from "@apollo/client";
import {BASE_URL} from "../config/consts";

class LocalizedApolloClient {
  private language: string = '';
  private client: ApolloClient<NormalizedCacheObject>;
  constructor() {
    this.client = new ApolloClient({
      uri: `${BASE_URL}/api`,
      cache: new InMemoryCache({dataIdFromObject: (res) => {
        const defaultId = defaultDataIdFromObject(res)
        return defaultId ? defaultId + ':' + this.language : defaultId
      }}
      )
    });
  }

  public query(language: string , options: QueryOptions<OperationVariables, any>): Promise<ApolloQueryResult<any>> {
    this.language = language;
    return this.client.query(options)
  }
}

export const localizedApolloClient: LocalizedApolloClient = new LocalizedApolloClient()

