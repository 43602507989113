import React, { useEffect, useRef } from 'react';
// import "styling/buttons.scss";^
import { Link } from './Link';
import lottie from 'lottie-web';
import animationData from '../animations/arrow.json';
import { motion } from 'framer-motion';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';

export enum ctaType {
  'plainText',
  'plaintTextLinkButton',
  'secondaryLinkButton',
  'primaryLinkButton',
  'primaryButton',
  'secondaryButton',
  'animatedLinkButton',
  'readMoreButton',
}

export const ConvertStringToCtaType = (theString: string): ctaType => {
  switch (theString.toLowerCase()) {
    case 'plaintextlinkbutton':
      return ctaType.plaintTextLinkButton;
    case 'plaintext':
      return ctaType.plainText;
    case 'secondarylinkbutton':
      return ctaType.secondaryLinkButton;
    case 'primarylinkbutton':
      return ctaType.primaryLinkButton;
    case 'primarybutton':
      return ctaType.primaryButton;
    case 'secondarybutton':
      return ctaType.secondaryButton;
    case 'animatedlinkbutton':
      return ctaType.animatedLinkButton;
    case 'readmorebutton':
      return ctaType.readMoreButton;
  }
};

type LinkButtonProps = {
  to: string;
  label: string;
  className?: string;
  newTab?: boolean;
};
type ButtonProps = {
  action?: (ev: any) => void;
  label: string;
  disabled?: boolean;
  className?: string;
  spinnerCss?: string;
  spinnerSize?: number;
};
export type CallToActionProps = {
  ctaType: ctaType;
  title: string;
  ctaUrl?: string;
  ctaIcon?: string;
  action?: (ev: any) => any;
  className?: string;
  linkClass?: string;
  id?: string;
  target?: boolean;
  delay?: number;
};
export const PrimaryLinkButton = (props: LinkButtonProps) => (
  <Link
    href={props.to}
    className={`cta button primary ${props.className ? props.className : ''}`}
    target={props.newTab ? '_blank' : ''}
  >
    {props.label}
  </Link>
);
export const SecondaryLinkButton = (props: LinkButtonProps) => (
  <Link
    href={props.to}
    className={`cta button secondary ${props.className ? props.className : ''}`}
    target={props.newTab ? '_blank' : ''}
  >
    {props.label}
  </Link>
);
export const PrimaryActionButton = (props: ButtonProps) => (
  <button
    onClick={props.action}
    className={`cta button primary ${props.className ? props.className : ''}`}
    disabled={props.disabled}
  >
    {props.disabled ? (
      <ClipLoader
        color={'#fff'}
        loading={true}
        speedMultiplier={0.75}
        css={css`
          ${props.spinnerCss}
        `}
        size={props.spinnerSize}
      />
    ) : (
      props.label
    )}
  </button>
);
export const SecondaryActionButton = (props: ButtonProps) => (
  <button onClick={props.action} className="cta button secondary">
    {props.label}
  </button>
);
export const RoundedButton = (props: ButtonProps) => (
  <button
    onClick={props.action}
    className={`button rounded ${props.className ? props.className : ''}`}
  >
    {props.label}
  </button>
);

export const CallToAction = (props: CallToActionProps) => {
  let theType = props.ctaType;
  if (typeof props.ctaType === 'string') {
    theType = ConvertStringToCtaType(props.ctaType);
  }
  const animatedArrow = useRef();

  useEffect(() => {
    setTimeout(() => {
      lottie.loadAnimation({
        container: animatedArrow.current,
        animationData,
        loop: false,
      });
    }, props.delay * 1000 || 2500);

    return () => lottie.destroy();
  }, [props.delay]);

  const sentenceState = {
    hidden: { opacity: 0, y: '-100%', scaleY: 1.5 },
    visible: {
      opacity: 1,
      y: 0,
      scaleY: 1,
      transition: {
        delay: props.delay || 2.5,
        duration: 0.3,
        ease: 'easeOut',
      },
    },
  };

  switch (theType) {
    case ctaType.plaintTextLinkButton:
      return (
        <Link
          href={props.ctaUrl}
          className={props.linkClass}
          target={props.target ? '_blank' : ''}
        >
          <div className={`cta ${props.className ? props.className : ''}`}>
            {props.className?.includes('icon-before') && props.ctaIcon ? (
              <i className={`fas fa-${props.ctaIcon}`} />
            ) : (
              ''
            )}
            <span>{props.title} </span>
            {!props.className?.includes('icon-before') && props.ctaIcon ? (
              <i className={`fas fa-${props.ctaIcon}`} />
            ) : (
              ''
            )}
          </div>
        </Link>
      );
    case ctaType.plainText:
      return (
        <div
          onClick={props.action}
          className={`cta ${props.className ? props.className : ''}`}
        >
          {props.className?.includes('icon-before') && props.ctaIcon ? (
            <i className={`fas fa-${props.ctaIcon}`} />
          ) : (
            ''
          )}
          <span>{props.title} </span>
          {!props.className?.includes('icon-before') && props.ctaIcon ? (
            <i className={`fas fa-${props.ctaIcon}`} />
          ) : (
            ''
          )}
        </div>
      );
    case ctaType.readMoreButton:
      return (
        <Link
          href={props.ctaUrl}
          target={props.target ? '_blank' : ''}
          className={'flex-bottom'}
        >
          <div
            className={`cta read-more-cta ${
              props.className ? props.className : ''
            }`}
          >
            {props.title}
          </div>
        </Link>
      );
    case ctaType.animatedLinkButton:
      return (
        <div
          onClick={props.action}
          className={`cta animated ${props.className ?? ''}`}
        >
          <motion.span
            variants={sentenceState}
            initial={'hidden'}
            animate={'visible'}
          >
            {props.title}
          </motion.span>
          <div className={'animated-arrow'} ref={animatedArrow} />
        </div>
      );
    case ctaType.secondaryLinkButton:
      return (
        <SecondaryLinkButton
          className={props.className}
          label={props.title}
          to={props.ctaUrl}
          newTab={props.target}
        />
      );
    case ctaType.primaryLinkButton:
      return (
        <PrimaryLinkButton
          className={props.className}
          label={props.title}
          to={props.ctaUrl}
          newTab={props.target}
        />
      );
    case ctaType.primaryButton:
      return (
        <PrimaryActionButton
          className={props.className}
          label={props.title}
          action={props.action}
        />
      );
    case ctaType.secondaryButton:
      return (
        <SecondaryActionButton label={props.title} action={props.action} />
      );
    default:
      console.error('unhandled ctaType: ', props.ctaType);
      return (
        <span className={`cta ${props.className ? props.className : ''}`}>
          {props.title}
        </span>
      );
  }
};
