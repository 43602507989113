import gql from 'graphql-tag';
import { GQLFunction } from '../../types';
import { GQL } from 'utils/graphql';
import { getLanguage } from 'utils/common';

export const GetCookieTermsQuery = gql`
  query getCareerOverview($lang: [QueryArgument]) {
    cookieTerms: entries(siteId: $lang, type: "cookieTerms") {
      ... on cookieTerms_cookieTerms_Entry {
        id
        localized {
          uri
          language
        }
        seoTitle
        seoDescription
        seoImage {
          id
        }
        cookieBlock {
          ... on cookieBlock_cookieBlock_BlockType {
            id
            cookieTitle
            cookieDescription
          }
        }
      }
    }
  }
`;

export const getCookiePageData: GQLFunction = async (context, variables) => {
  const { data } = await GQL(
    GetCookieTermsQuery,
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};
