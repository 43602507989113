import axios from 'axios';
import { GQLFetch } from '../../types';
import { BASE_URL } from '../../config/consts';
import { print } from 'graphql';
import { localizedApolloClient } from 'apollo-client';
import { defaultVariables } from 'consts/default.consts';

export const GQL: GQLFetch = async (query, variables, context) => {
  if (context?.preview) {
    const { data } = await axios.post(
      // @ts-ignore
      `${BASE_URL}/api?token=${context?.previewData?.previewToken}`,
      {
        query: print(query),
        variables: { ...defaultVariables, ...variables },
      }
    );

    return data;
  }
  const mergedVariables = { ...defaultVariables, ...variables };
  return localizedApolloClient.query(mergedVariables.lang, {
    query,
    variables: mergedVariables,
  });
};

export const PrivateGQL: GQLFetch = (query, variables) =>
  axios.post(
    `${BASE_URL}/api`,
    {
      query: print(query),
      variables: { ...defaultVariables, ...variables },
    },
    {
      headers: {
        Authorization: `Bearer ${process.env.GQL_AUTH_TOKEN}`,
      },
    }
  );
