import React, {HTMLProps} from 'react';
import {SectionHeader} from "./Text";

interface SectionProps extends HTMLProps<HTMLElement> {
  title?: string;
  subtitle?: string;
}

export const Section = ({id, className, children, title, subtitle, ...props}: SectionProps) => <section id={id}
                                                                                                        className={`section ${className || ''}`} {...props}>
  <SectionHeader
    title={title}
    subtitle={subtitle}
  />
  {children}
</section>
