import {Image} from "./Image";
import React from "react";
// import '../styling/reference.scss';
import {RedactorContent} from "./redactor-content";

export const Reference = (props) =>
  <div className="reference">
    {props.img &&
    <Image className="reference-img" alt={`${props.client} reference`} data-src={props.img.url} width={props.img.width}
           height={props.img.height}/>}
    <p
      className="reference-client">{props.client}</p>
    <h1 className="reference-title">{props.title}</h1>
    <RedactorContent className="reference-description" html={props.description}/>

  </div>;
