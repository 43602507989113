import gql from 'graphql-tag';
import { GQLFunction } from '../../types';
import { GQL } from 'utils/graphql';
import { getLanguage } from 'utils/common';
import { defaultVariables } from 'consts/default.consts';

export const HomeQuery = gql`
  query HomeQuery($lang: [QueryArgument]) {
    homepage: entry(siteId: $lang, type: "home") {
      id
      localized {
        uri
        language
      }
      title
      ... on home_home_Entry {
        id
        seoTitle
        seoDescription
        ctaBanner {
          ... on ctaBanner_ctaBanner_Entry {
            id
            title
            bannerTitle
            bannerDescription
            bannerStyle
            bannerButton {
              ... on callToAction_callToAction_Entry {
                id
                title
                ctaType
                ctaUrl
              }
            }
          }
        }
        seoImage {
          url
        }
        heroes {
          ... on heroes_hero_BlockType {
            id
            heroTitle
            subtitle
            description
            showServices
            calltoaction {
              ... on callToAction_callToAction_Entry {
                id
                title
                ctaUrl
                ctaType
                ctaIcon
              }
            }
          }
        }
        advantagesBlock {
          ... on advantagesBlock_advantages_BlockType {
            id
            advantagesTitle
            advantagesSubtitle
          }
        }
        teaser {
          ... on teaser_teaserBlock_BlockType {
            id
            teaserTitle
            teaserImage {
              id
              url @transform(width: 778, mode: "fit", immediately: true)
              width
              height
              focalPoint
            }
            teaserSubtitle
            teaserDescription
            callToAction {
              ... on callToAction_callToAction_Entry {
                id
                title
                ctaUrl
                ctaType
                ctaClass
                ctaTargetBlank
              }
            }
            customCtaUrl
          }
        }
        advantagesContent {
          ... on advantagesContent_advantage_BlockType {
            id
            advantageLogo {
              url @transform(height: 150, mode: "fit", immediately: true)
              width
              height
              focalPoint
            }
            advantageContentTitle
            advantageContentDescription
          }
        }
        extraNews {
          ... on extraNews_extraNews_BlockType {
            id
            header
            subheader
            description
            image {
              id
              url
              width
              height
              focalPoint
            }
            cta {
              ... on callToAction_callToAction_Entry {
                id
                title
                ctaUrl
                ctaType
                ctaClass
                ctaTargetBlank
              }
            }
          }
        }
        audienceBlock {
          ... on audienceBlock_targetAudienceBlock_BlockType {
            id
            audienceTitle
            audienceSubtitle
            audienceDescription
          }
        }
        audienceContent {
          ... on audienceContent_audienceContent_BlockType {
            id
            audienceLogo {
              url @transform(height: 120, mode: "fit", immediately: true)
              width
              height
              focalPoint
            }
            audienceContentTitle
            audienceContentDescription
          }
        }
        casesBlock {
          ... on casesBlock_cases_BlockType {
            id
            casesTitle
            casesSubtitle
            selectedCases {
              ... on cases_cases_Entry {
                id
                title
                slug
                caseHero {
                  url @transform(width: 778, mode: "fit", immediately: true)
                  width
                  height
                }
                caseIntro
                uri
                caseThumbCta {
                  ... on callToAction_callToAction_Entry {
                    title
                    ctaType
                  }
                }
                caseServices {
                  ... on services_services_Entry {
                    id
                    title
                    teamHero {
                      ... on teamHero_hero_BlockType {
                        id
                        logo {
                          url
                            @transform(
                              height: 360
                              mode: "fit"
                              immediately: true
                            )
                          width
                          height
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        subfields {
          ... on subfields_subfield_BlockType {
            id
            subfieldTitle
            subfieldDescription
            subfieldCallToAction {
              ... on callToAction_callToAction_Entry {
                id
                title
                ctaType
                ctaUrl
                ctaIcon
              }
            }
          }
        }
        referralsBlock {
          ... on referralsBlock_referrals_BlockType {
            id
            referralTitle
            referralSubtitle
          }
        }
        logos {
          ... on logos_logo_BlockType {
            id
            website
            image {
              url @transform(height: 180, mode: "fit", immediately: true)
              width
              height
            }
          }
        }
        bloglist {
          ... on bloglist_blogheaders_BlockType {
            id
            header
            subheader
          }
        }
      }
    }
    referrals: entries(siteId: $lang, type: "referrals") {
      ... on referrals_referrals_Entry {
        id
        title
        referralCustomer
        referralDescription
        referralImage {
          id
          url
          width
          height
        }
      }
    }
    services: entries(siteId: $lang, type: "services", withStructure: true) {
      ... on services_services_Entry {
        id
        title
        uri
        teamHero {
          ... on teamHero_hero_BlockType {
            id
            logo {
              url @transform(height: 44, mode: "fit", immediately: true)
              width
              height
              focalPoint
            }
          }
        }
      }
    }
  }
`;

export const getHomePageData: GQLFunction = async (
  context,
  variables = defaultVariables
) => {
  const { data } = await GQL(
    HomeQuery,
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};
