import gql from 'graphql-tag';
import { GQLFunction } from '../../types';
import { GQL } from 'utils/graphql';
import { getLanguage } from 'utils/common';

export const GetAllCasesQuery = gql`
  query getAllCases(
    $lang: [QueryArgument]
    $offset: Int
    $limit: Int
    $teamIds: [QueryArgument]
  ) {
    teams: entries(siteId: $lang, type: "services") {
      ... on services_services_Entry {
        slug
        title
        id
      }
    }
    loadMore: entries(siteId: $lang, type: "callToAction", slug: "meer-laden") {
      title
    }
    total: entries(siteId: $lang, type: "cases", relatedTo: $teamIds) {
      id
    }
    cases: entries(
      siteId: $lang
      type: "cases"
      offset: $offset
      limit: $limit
      visibleFor: $teamIds
      orderBy: "postDate DESC"
    ) {
      id
      localized {
        slug
        language
        siteId
      }
      ... on cases_cases_Entry {
        id
        uri
        slug
        title
        highlighted
        caseHero {
          url
          width
          height
        }
        caseIntro
        caseServices {
          ... on services_services_Entry {
            title
            id
            teamHero {
              ... on teamHero_hero_BlockType {
                id
                logo {
                  url @transform(width: 44, mode: "fit", immediately: true)
                  width
                  height
                }
              }
            }
          }
        }
        visibleFor {
          ... on services_services_Entry {
            id
            title
          }
        }
      }
    }
  }
`;

export const getAllCasesData: GQLFunction = async (context, variables) => {
  const { data } = await GQL(
    GetAllCasesQuery,
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};

export const GetCaseQuery = gql`
  query getCase($lang: [QueryArgument], $slug: [String]) {
    theCase: entry(siteId: $lang, slug: $slug, type: "cases") {
      ... on cases_cases_Entry {
        id
        title
        localized {
          uri
          language
        }
        seoTitle
        seoDescription
        seoImage {
          url
        }
        caseServicesTitle
        caseHero {
          url
            @transform(
              width: 1140
              quality: 100
              mode: "fit"
              immediately: true
            )
          width
          height
        }
        caseIntro
        caseServices {
          ... on services_services_Entry {
            id
            title
            teamHero {
              ... on teamHero_hero_BlockType {
                id
                logo {
                  url
                    @transform(
                      width: 56
                      quality: 100
                      mode: "fit"
                      immediately: true
                    )
                  width
                  height
                }
              }
            }
          }
        }
        caseContentBlocks {
          ... on caseContentBlocks_caseContentBlock_BlockType {
            id
            blockTitle
            blockContent
          }
        }
        caseCustomerBlock {
          ... on caseCustomerBlock_customerinfo_BlockType {
            id
            customerTitle
            customerDescription
          }
        }
        casePortfolioBlock {
          ... on casePortfolioBlock_casePortfolioBlock_BlockType {
            id
            caseBlockTitle
            caseBlockImage {
              id
              url
                @transform(
                  width: 1140
                  quality: 100
                  mode: "fit"
                  immediately: true
                )
              width
              height
              focalPoint
            }
            caseBlockCategories {
              id
              title
            }
            caseBlockSubtitle
            caseBlockImages {
              id
              url @transform(width: 1140, mode: "fit", immediately: true)
              width
              height
              focalPoint
            }
            caseBlockDescription
          }
        }
      }
    }
    newCaseFormat: entry(siteId: $lang, slug: $slug, type: "cases") {
      ... on cases_cases_Entry {
        id
        keesHero {
          ... on keesHero_keesHeroBlock_BlockType {
            id
            supportType
            keesTitle
            keesSubtitle
            keesHeroImage {
              url
              width
              height
              focalPoint
            }
            invertHeroText
          }
        }
        keesInfo {
          ... on keesInfo_keesInfoBlock_BlockType {
            id
            keesInfoCustomerTitle
            keesInfoCustomerDescription
            keesInfoCustomerWebsite
            keesInfoCustomerFacebook
            keesInfoCustomerInstagram
            keesInfoCustomerTwitter
            keesInfoSolutionTitle
            keesInfoSolutionDescription
            keesTeams {
              ... on services_services_Entry {
                id
                title
                teamHero {
                  ... on teamHero_hero_BlockType {
                    id
                    logo {
                      url
                        @transform(
                          width: 56
                          quality: 100
                          mode: "fit"
                          immediately: true
                        )
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
        keesContent {
          ... on keesContent_keesContentBlock_BlockType {
            id
            keesContentTitle
            keesContentText
            keesContentImages {
              url
              width
              height
              focalPoint
            }
          }
        }
        keesUniker {
          ... on keesUniker_keesUnikerBlock_BlockType {
            id
            keesUnikerTitle
            keesUnikerPerson {
              ... on uniker_uniker_Entry {
                id
                title
                unikerJobTitle
                unikerPhoto {
                  id
                  url @transform(width: 778, mode: "fit", immediately: true)
                  width
                  height
                  focalPoint
                }
              }
            }
            keesUnikerTeamMail
          }
        }
      }
    }
    common: entry(siteId: $lang, type: "case") {
      ... on case_case_Entry {
        id
        categoryLabel
      }
    }
  }
`;

export const getCaseData: GQLFunction = async (context, variables) => {
  const { data } = await GQL(
    GetCaseQuery,
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};
