import gql from 'graphql-tag';
import { GQLFunction } from '../../types';
import { GQL } from 'utils/graphql';
import { getLanguage } from 'utils/common';

export const NotFoundQuery = gql`
  query getNotFound($lang: [QueryArgument]) {
    entry(siteId: $lang, type: "notFound") {
      ... on notFound_notFound_Entry {
        id
        title
        localized {
          uri
          language
        }
        notFound {
          ... on notFound_notFoundBlock_BlockType {
            id
            heading
            description
            back {
              id
              ... on callToAction_callToAction_Entry {
                id
                ctaType
                title
                ctaUrl
              }
            }
            extrabutton {
              id
              ... on callToAction_callToAction_Entry {
                id
                ctaType
                title
                ctaUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const getNotFoundPageData: GQLFunction = async (context, variables) => {
  const { data } = await GQL(
    NotFoundQuery,
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};
