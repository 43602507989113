import gql from 'graphql-tag';
import { GQLFunction } from '../../types';
import { GQL } from 'utils/graphql';
import { getLanguage } from 'utils/common';

export const TeamOverviewQuery = gql`
  query getTeamOverview($lang: [QueryArgument]) {
    entry(siteId: $lang, type: "teamOverview") {
      ... on teamOverview_teamOverview_Entry {
        id
        localized {
          uri
          language
        }
        seoDescription
        seoImage {
          url
        }
        seoTitle
        teamOverviewHero {
          ... on teamOverviewHero_teamOverviewHero_BlockType {
            id
            heroTitle
            heroDescription
            heroImage {
              id
              url @transform(width: 320, mode: "fit")
              width
              height
              focalPoint
            }
          }
        }
        teamOverviewCTABanner {
          ... on ctaBanner_ctaBanner_Entry {
            id
            title
            bannerTitle
            bannerDescription
            bannerStyle
            bannerButton {
              ... on callToAction_callToAction_Entry {
                id
                title
                ctaType
                ctaUrl
              }
            }
          }
        }
      }
    }
    unikers: entries(siteId: $lang, type: "uniker") {
      id
      slug
      localized {
        slug
        url
        language
        siteId
      }
      ... on uniker_uniker_Entry {
        id
        title
        unikerDescription
        unikerJobTitle
        unikerLinkedIn
        unikerPhoto {
          id
          url @transform(width: 778, mode: "fit", immediately: true)
          width
          height
          focalPoint
        }
      }
    }
  }
`;

export const getTeamOverviewPageData: GQLFunction = async (
  context,
  variables
) => {
  const { data } = await GQL(
    TeamOverviewQuery,
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};
export const UnikerQuery = gql`
  query getUniker($lang: [QueryArgument], $slug: [String]) {
    entry(siteId: $lang, type: "uniker", slug: $slug) {
      slug
      localized {
        slug
        url
        language
      }
      ... on uniker_uniker_Entry {
        unikerPhoto {
          id
          url
          width
          height
          focalPoint
        }
        unikerJobTitle
        unikerLinkedIn
        unikerDescription
        title
        unikerTeam {
          title
          slug
          ... on services_services_Entry {
            teamHero {
              ... on teamHero_hero_BlockType {
                logo {
                  url @transform(width: 44, mode: "fit", immediately: true)
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getUnikerPageData: GQLFunction = async (context, variables) => {
  const { data } = await GQL(
    UnikerQuery,
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};
