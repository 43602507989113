import gql from 'graphql-tag';
import { GQLFunction } from '../../types';
import { GQL } from 'utils/graphql';
import { getLanguage } from 'utils/common';

export const CasesOverviewQuery = gql`
  query getCaseOverview($lang: [QueryArgument]) {
    entry(siteId: $lang, type: "case") {
      id
      title
      localized {
        uri
        language
      }
      ... on case_case_Entry {
        id
        seoTitle
        seoDescription
        seoImage {
          url
        }
        mainCase {
          ... on cases_cases_Entry {
            id
            uri
            title
            caseIntro
            caseHero {
              url
            }
          }
        }
        cta {
          ... on callToAction_callToAction_Entry {
            id
            ctaType
            title
          }
        }
        ctaBanner {
          ... on ctaBanner_ctaBanner_Entry {
            id
            title
            bannerTitle
            bannerDescription
            bannerStyle
            bannerButton {
              ... on callToAction_callToAction_Entry {
                id
                title
                ctaType
                ctaUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const getCaseOverviewPageData: GQLFunction = async (
  context,
  variables
) => {
  const { data } = await GQL(
    CasesOverviewQuery,
    { ...variables, lang: getLanguage(context) },
    context
  );
  return data;
};
