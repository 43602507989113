import React from 'react';
import {ItemList, WithContext} from "schema-dts";
import {sanitizeHtml} from "./redactor-content";

export const ServicesStructuredDataItemList = ({services}) => {
  const data: WithContext<ItemList> = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    itemListElement: services.map((service, idx) => ({
      "@type": "ListItem",
      position: idx + 1,
      url: service.url,
    }))
  };
  return (
    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: sanitizeHtml(JSON.stringify(data))
    }}/>);
};

