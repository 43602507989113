import React from 'react';
import { useRouter } from "next/router";
import { RoundedButton } from "./Buttons";

export const SpecialtyNavigation = ({specialties}) => {
  const router = useRouter();

  return <div id="service-navigation">
    {specialties.map(({ id, title, slug, parent }) => (
      <RoundedButton
        key={id}
        label={title}
        className={slug === router.query['specialty-slug'] ? 'active' : ''}
        action={() => router.push(`/${router.locale}/services/${parent.slug}/${slug}`)}
      />
    ))}
  </div>
}
