import React, {useEffect, useState} from "react";
import {Quote} from "./Text";
import {CallToAction, CallToActionProps} from "./Buttons";
import {useInView} from "react-intersection-observer";
// import '../styling/hero.scss';
import {TeamBlock} from "./TeamBlock";
import {useRouter} from "next/router";
import {RedactorContent} from "./redactor-content";
import {useWindowSize} from "hooks";

type Hero = {
  heroTitle: string;
  subtitle: string;
  description: string;
  calltoaction: CallToActionProps[];
  showServices: boolean;
}

export const Hero = ({hero, idx, id, className, services}: { hero: Hero; idx: number; isMobile?: boolean; id?: string; className?: string; services?: any }) => {
  const [ref, inView] = useInView({
    threshold: idx === 0 ? 0.8 : 0.8,
    rootMargin: idx === 0 ? "-134px 0px 0px 0px" : "0px 0px 0px 0px"
  });
  const router = useRouter();
  const [points, setPoints] = useState('');
  const {width} = useWindowSize();

  useEffect(() => {
    const fin = document.getElementById('service-51');
    const rec = document.getElementById('service-55');
    const mar = document.getElementById('service-59');
    const des = document.getElementById('service-63');
    const dev = document.getElementById('service-23');
    const all = width > 575 ? [dev, mar, rec, fin, des, rec, dev, des] : width > 371 ? [des, dev, mar, des, rec, fin, mar] : [fin, rec, mar, des, dev];
    setPoints(all.map(ser => `${ser.offsetLeft + ser.offsetWidth / 2},${ser.offsetTop + ser.offsetHeight / 2}`).join(' '));
  }, [width]);
  return (<div className={`hero grid ${className}`} id={id || ''}>
    <div ref={ref} className={`hero-content ${inView ? "visible" : "hidden"}`}>
      <div className="hero-text">
        <Quote>{hero.heroTitle}</Quote>
        <h2>{hero.subtitle}</h2>
        {hero.description ?
          <RedactorContent className="hero-description" html={hero.description}/> : ""}
      </div>
      <div className="hero-buttons">
        {hero.calltoaction.map(call => <CallToAction key={call.id} {...call}/>)}
      </div>
      {hero.showServices ? (<div ref={ref} className={`teams-wrapper ${inView ? 'activated' : ''}`}>
        <svg height={"100%"} width={"100%"} fillRule={"evenodd"}>
          <polyline
            id="ants"
            fill='none'
            className="path"
            stroke="#A0B7D4"
            points={points}
            strokeWidth={3}
            strokeDasharray={"0, 10"}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {services.map(service => (<TeamBlock
          key={service.id}
          id={`service-${service.id}`}
          name={service.title}
          img={service.teamHero[0].logo[0]}
          url={`/${router.locale}/${service.uri}`}
        />))}
      </div>) : ''}
    </div>
  </div>);
};
