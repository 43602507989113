import {Image} from "./Image";
import React from "react";
import {CallToAction, CallToActionProps} from "./Buttons";
import {useInView} from "react-intersection-observer";
import {useRouter} from "next/router";
// import '../styling/team-block.scss';
import {RedactorContent} from "./redactor-content";

type TeamBlockProps = { id?: string; name: string; img: { url: string; width: string | number; height: string | number; }; description?: string; callToAction?: CallToActionProps; onClick?: (any) => any; url?: string }
export const TeamBlock = (props: TeamBlockProps) =>
  props.description ? (
    <div id={props.id} className={`team-description-block ${props.id ? 'team-' + props.id : ''} `}>
      <div className="team-content">
        <div className="team-header">
          <Image alt={props.name} data-src={props.img.url} width={props.img.width} height={props.img.height}/>
          <h3>{props.name}</h3>
        </div>
        <RedactorContent className="team-description" html={props.description}/>
      </div>
      <div className="team-footer">
        {props.callToAction && <CallToAction {...props.callToAction}/>}
      </div>
    </div>
  ) : (
    <a href={props.url}>
      <div id={props.id} className={`team-block ${(props.onClick || props.url) ? 'clickable' : ''}`}
           onClick={props.onClick}>
        <h3>{props.name}</h3>
        <Image alt={props.name} data-src={props.img.url} width={props.img.width} height={props.img.height}/>
      </div>
    </a>
  );

export const Teams = ({style}: { style?: any }) => {
  const [ref, inView] = useInView({threshold: 0, rootMargin: '200px 0px', triggerOnce: true});
  const router = useRouter();

  return (
    <div ref={ref} className={`teams-wrapper ${inView ? 'activated' : ''}`} style={style}>
      <FinAdminTeamBlock onClick={() => router.push("/services/[team-slug]", "/services/finance-administratie")}/>
      <RecrTeamBlock onClick={() => router.push("/services/[team-slug]", "/services/recruitment-talentbeheer")}/>
      <MarComTeamBlock onClick={() => router.push("/services/[team-slug]", "/services/marketing-communicatie")}/>
      <DesignTeamBlock onClick={() => router.push("/services/[team-slug]", "/services/graphic-design")}/>
      <DevTeamBlock onClick={() => router.push("/services/[team-slug]", "/services/technology-development")}/>
    </div>);
};

type SpecificTeamBlockProps = { description?: string; callToAction?: CallToActionProps; onClick?: (any) => any };
export const FinAdminTeamBlock = (props: SpecificTeamBlockProps) => (
  <TeamBlock
    name="Finance & administration"
    description={props.description}
    img={{url: "/teams/finadmin.svg", width: "44px", height: "44px"}}
    callToAction={props.callToAction}
    onClick={props.onClick}
  />);
export const RecrTeamBlock = (props: SpecificTeamBlockProps) => (
  <TeamBlock
    name="Talent mgmt & recruitment"
    description={props.description}
    img={{url: "/teams/recruitment.svg", width: "44px", height: "44px"}}
    callToAction={props.callToAction}
    onClick={props.onClick}
  />);
export const MarComTeamBlock = (props: SpecificTeamBlockProps) => (
  <TeamBlock
    name="Marketing & Communication"
    description={props.description}
    img={{url: "/teams/marcom.svg", width: "44px", height: "44px"}}
    callToAction={props.callToAction}
    onClick={props.onClick}
  />);
export const DesignTeamBlock = (props: SpecificTeamBlockProps) => (
  <TeamBlock
    name="Graphic & Digital design"
    description={props.description}
    img={{url: "/teams/graphic_design.svg", width: "44px", height: "44px"}}
    callToAction={props.callToAction}
    onClick={props.onClick}
  />);
export const DevTeamBlock = (props: SpecificTeamBlockProps) => (
  <TeamBlock
    name="Technology & development"
    description={props.description}
    img={{url: "/teams/dev.svg", width: "44px", height: "44px"}}
    callToAction={props.callToAction}
    onClick={props.onClick}
  />);
