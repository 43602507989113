import React from 'react';

export const BGShapesV2 = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 810" preserveAspectRatio="xMidYMid slice">
    <title>bgshapes-v2</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <linearGradient x1="81.7750969%" y1="28.0711372%" x2="34.6474905%" y2="71.9288628%" id="linearGradient-1">
        <stop stopColor="#F2F5F9" offset="0%"/>
        <stop stopColor="#E1E9F3" offset="100%"/>
      </linearGradient>
      <linearGradient x1="47.8310924%" y1="47.4921941%" x2="53.5548497%" y2="103.925444%" id="linearGradient-2">
        <stop stopColor="#F2F5F9" offset="0%"/>
        <stop stopColor="#E1E9F3" offset="100%"/>
      </linearGradient>
      <linearGradient x1="4.29213259%" y1="45.5896236%" x2="80.6447864%" y2="47.5383067%" id="linearGradient-3">
        <stop stopColor="#F2F5F9" offset="0%"/>
        <stop stopColor="#E2EAF3" offset="77.5289636%"/>
        <stop stopColor="#E1E9F3" offset="100%"/>
      </linearGradient>
      <linearGradient x1="52.3684751%" y1="66.3344263%" x2="62.272039%" y2="14.716411%" id="linearGradient-4">
        <stop stopColor="#F2F5F9" offset="0%"/>
        <stop stopColor="#E1E9F3" offset="100%"/>
      </linearGradient>
      <linearGradient x1="44.210003%" y1="14.9205137%" x2="50%" y2="100%" id="linearGradient-5">
        <stop stopColor="#F2F5F9" offset="0%"/>
        <stop stopColor="#E1E9F3" offset="100%"/>
      </linearGradient>
    </defs>
    <g id="bgshapes-v2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="#FFFFFF" x="0" y="0" width="1440" height="810"/>
      <rect id="bg" fillOpacity="0.996856" fill="#F2F5F9" x="0" y="-3" width="1440" height="814"/>
      <polygon id="slash" fillOpacity="0.75" fill="url(#linearGradient-1)"
               points="327.947274 822 401 722 326.417728 722 250 822"/>
      <path
        d="M1626.81499,336.591131 C1621.18047,485.916167 1573.46074,570.385791 1483.6558,590 C1348.94838,619.421314 1292.34311,459.451 1096.97658,568.267638 C901.610051,677.084276 759.329476,510.170232 830.718231,343 C902.106986,175.829768 1149.82444,171.01431 1224.23707,182.140025 C1298.64971,193.26574 1394.80154,224.025071 1438.30406,171.668764 C1481.80658,119.312456 1585.38758,-108.529899 1613.91231,-46.3326734 C1632.9288,-4.86785645 1637.22969,122.773412 1626.81499,336.591131 Z"
        id="Path-2-Copy" fillOpacity="0.75" fill="url(#linearGradient-2)"/>
      <path
        d="M1224.41586,585 C1226.58022,591.460917 1230.04259,597.608078 1234.83945,603.478039 C1256.936,630.314755 1302.78581,624.600149 1318.00626,593.352585 C1319.65689,590.00485 1320.79683,587.400378 1321.66014,585 L1374,585 C1366.05995,633.071535 1325.59369,667.078925 1273.09879,667 C1220.48535,666.880924 1180.02212,632.992335 1172,585 L1224.41586,585 Z"
        id="Fill-8-Copy-3" fillOpacity="0.61" fill="url(#linearGradient-3)"
        transform="translate(1273.000000, 626.000000) rotate(210.000000) translate(-1273.000000, -626.000000) "/>
      <path
        d="M424.143157,190.436016 C495.758813,237.285012 485.760833,361.697535 452.767499,401.564566 C419.774165,441.431597 271.105124,549.349916 94.1203367,417.341343 C-82.8644506,285.33277 -325.129315,273.079408 -426.054944,300.290782 C-526.980572,327.502156 -698,297.501434 -698,63.4826317 C-698,-170.536171 -458.107206,-327.49941 -278.237268,-227.155685 C-98.3673294,-126.81196 -25.6711381,33.0713183 103.944276,88.3962836 C233.559691,143.721249 352.527502,143.58702 424.143157,190.436016 Z"
        id="shape-left-copy" fillOpacity="0.75" fill="url(#linearGradient-4)"/>
      <path
        d="M339,25.4979068 C339,56.3686855 359.934596,78 387.906813,78 C415.874979,78 437,56.3686855 437,25.4979068 C437,-5.3728719 415.874979,-27 387.906813,-27 C359.934596,-27 339,-5.3728719 339,25.4979068 M489,25.9979126 C489,83.2505062 446.441794,126 388.809028,126 C331.363171,126 289,83.2505062 289,25.9979126 C289,-31.2546809 331.363171,-74 388.809028,-74 C446.441794,-74 489,-31.2546809 489,25.9979126"
        id="Fill-8-Copy-9" fillOpacity="0.75" fill="url(#linearGradient-5)"
        transform="translate(389.000000, 26.000000) scale(1, -1) translate(-389.000000, -26.000000) "/>
    </g>
  </svg>

);
export const BGShapes = () => (
  <svg viewBox="0 0 1440 810" preserveAspectRatio="xMidYMid slice" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>bgshapes</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <linearGradient x1="47.8310924%" y1="47.4899898%" x2="53.5548497%" y2="103.972843%" id="linearGradient-1">
        <stop stopColor="#F2F5F9" offset="0%"/>
        <stop stopColor="#E1E9F3" offset="100%"/>
      </linearGradient>
      <linearGradient x1="52.3684751%" y1="66.3344263%" x2="62.272039%" y2="14.716411%" id="linearGradient-2">
        <stop stopColor="#F2F5F9" offset="0%"/>
        <stop stopColor="#E1E9F3" offset="100%"/>
      </linearGradient>
      <linearGradient x1="4.29213259%" y1="45.5896236%" x2="80.6447864%" y2="47.5383067%" id="linearGradient-3">
        <stop stopColor="#F2F5F9" offset="0%"/>
        <stop stopColor="#E2EAF3" offset="77.5289636%"/>
        <stop stopColor="#E1E9F3" offset="100%"/>
      </linearGradient>
      <linearGradient x1="81.7750969%" y1="28.0711372%" x2="34.6474905%" y2="71.9288628%" id="linearGradient-4">
        <stop stopColor="#F2F5F9" offset="0%"/>
        <stop stopColor="#E1E9F3" offset="100%"/>
      </linearGradient>
      <linearGradient x1="53.5548497%" y1="14.7783934%" x2="53.5548497%" y2="100%" id="linearGradient-5">
        <stop stopColor="#F2F5F9" offset="0%"/>
        <stop stopColor="#E1E9F3" offset="100%"/>
      </linearGradient>
      <linearGradient x1="44.210003%" y1="14.9205137%" x2="50%" y2="100%" id="linearGradient-6">
        <stop stopColor="#F2F5F9" offset="0%"/>
        <stop stopColor="#E1E9F3" offset="100%"/>
      </linearGradient>
    </defs>
    <g id="bgshapes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      {/*<rect fill="#FFFFFF" x="0" y="0" width="1440" height="750"></rect>*/}
      <rect id="bg" fillOpacity="0.996856" fill="#F2F5F9" x="0" y="-3" width="1440" height="753"/>
      <path
        d="M1705.4742,215.599379 C1699.84203,364.927545 1652.14226,449.398938 1562.37488,469.013559 C1427.72381,498.43549 1371.1422,338.461823 1175.85739,447.280741 C980.572572,556.09966 838.351506,389.182118 909.710402,222.008383 C981.069299,54.8346476 1228.68314,50.0190881 1303.06466,61.1450365 C1377.44617,72.2709849 1473.55778,103.03096 1517.04211,50.6735556 C1560.52644,-1.68384862 1664.06411,-229.530979 1692.57691,-167.33245 C1711.58545,-125.866764 1715.88454,1.77717903 1705.4742,215.599379 Z"
        id="shape-right" fill="url(#linearGradient-1)"/>
      <path
        d="M851.143157,445.436016 C922.758813,492.285012 912.760833,616.697535 879.767499,656.564566 C846.774165,696.431597 698.105124,804.349916 521.120337,672.341343 C344.135549,540.33277 101.870685,528.079408 0.945056135,555.290782 C-99.9805724,582.502156 -271,552.501434 -271,318.482632 C-271,84.4638292 -31.1072064,-72.4994099 148.762732,27.8443148 C328.632671,128.18804 401.328862,288.071318 530.944276,343.396284 C660.559691,398.721249 779.527502,398.58702 851.143157,445.436016 Z"
        id="shape-left" fill="url(#linearGradient-2)"/>
      <path
        d="M699.415855,105 C701.580217,111.460917 705.042588,117.608078 709.839446,123.478039 C731.935998,150.314755 777.785812,144.600149 793.00626,113.352585 C794.65689,110.00485 795.796828,107.400378 796.660141,105 L849,105 C841.059954,153.071535 800.593686,187.078925 748.098795,187 C695.48535,186.880924 655.022122,152.992335 647,105 L699.415855,105 Z"
        id="c" fill="url(#linearGradient-3)"
        transform="translate(748.000000, 146.000000) rotate(210.000000) translate(-748.000000, -146.000000) "/>
      <polygon id="slash" fill="url(#linearGradient-4)"
               points="721.947274 748 795 648 720.417728 648 644 748"/>
      <polygon id="dash" fill="url(#linearGradient-5)" points="1262 146 1316 146 1316 63 1262 63"/>
      <path
        d="M1281.90681,674 C1309.87498,674 1331,652.368685 1331,621.497907 C1331,590.627128 1309.87498,569 1281.90681,569 C1253.9346,569 1233,590.627128 1233,621.497907 C1233,652.368685 1253.9346,674 1281.90681,674 Z M1282.80903,722 C1225.36317,722 1183,679.250506 1183,621.997913 C1183,564.745319 1225.36317,522 1282.80903,522 C1340.44179,522 1383,564.745319 1383,621.997913 C1383,679.250506 1340.44179,722 1282.80903,722 Z"
        id="circle" fill="url(#linearGradient-6)"/>
    </g>
  </svg>);
