import { useEffect, useState } from 'react';
// import '../styling/bloglist.scss';
// import '../styling/filters.scss';
import { useRouter } from 'next/router';
import { getAllBlogsData } from 'queries';
import { lozadObserver } from 'pages/_app';
import { Image } from 'components/Image';
import { RedactorContent } from 'components/redactor-content';
import { SocialButtons } from 'components/SocialButtons';
import { CallToAction, CallToActionProps, ctaType } from 'components/Buttons';
import { formatDateNumber } from 'utils/dates';
import { getTeamData } from 'utils/teamData';

const BlogOverviewBlock = ({
  entry,
  cta,
  hideSocials,
}: {
  entry: any;
  cta: CallToActionProps;
  hideSocials: boolean;
}) => {
  const router = useRouter();
  const handleClick = () => router.push(entry.uri);
  return (
    <div className={'blog-overview-block'}>
      <div className="blog-overview-hero clickable" onClick={handleClick}>
        <Image
          data-src={entry.blogImage[0].url}
          alt={'Blog preview image'}
          height={entry.blogImage[0].height}
          width={entry.blogImage[0].width}
        />
        <div className="buttons">
          <p className="blue-small blog-date">
            {formatDateNumber(entry.postDate)}
          </p>
          {/*<p className="blue-small blog-category">{entry.blogCategory[0].shortName}</p>*/}
        </div>
      </div>
      <h4 className="clickable" onClick={handleClick}>
        {entry.blogService.map(
          (service) =>
            service.title && (
              <Image
                key={service.teamHero[0].logo[0].id}
                alt={service.teamHero[0].logo[0].alt || service.title}
                width={service.teamHero[0].logo[0].width}
                height={service.teamHero[0].logo[0].height}
                url={service.teamHero[0].logo[0].url}
              />
            )
        )}
      </h4>
      <h1 className="clickable" onClick={handleClick}>
        {entry.title}
      </h1>
      <RedactorContent
        html={entry.blogDescription}
        className="blog-description clickable"
        onClick={handleClick}
      />
      <div className="blog-overview-block-footer">
        <CallToAction
          ctaType={cta.ctaType}
          title={cta.title}
          ctaUrl={entry.uri}
        />
        {!hideSocials && <SocialButtons uri={entry.uri} />}
      </div>
    </div>
  );
};

const fetchMore = async ({ variables, updateQuery, context }) => {
  const { blogs } = await getAllBlogsData(context, variables);
  updateQuery(blogs);
};

export const BlogList = ({
  hideFilters = false,
  hideSocials = false,
  limit,
  blogsData,
}: {
  cta?: CallToActionProps;
  hideSocials?: boolean;
  hideFilters?: boolean;
  limit?: number;
  blogsData: any;
}) => {
  const router = useRouter();
  useEffect(() => lozadObserver.observe);

  const [filter, setFilter] = useState([]);
  const {
    blogs,
    total: { length: totalCount },
    categories,
    loadMore,
    cta,
  } = blogsData;
  const [showBlogs, setShowBlogs] = useState(blogs);

  if (filter.length === 0) {
    setFilter(categories.map((c) => c.id));
  }

  const showAll =
    JSON.stringify(categories.map((c) => c.id)) === JSON.stringify(filter);
  if (showBlogs) {
    return (
      <section id="bloglist">
        {hideFilters ? (
          ''
        ) : (
          <div className="filters">
            <p className="filter-header">Filter op categorie</p>
            {categories.map(({ title, id, slug }) => (
              <div
                key={`filter-${title}-${id}`}
                onClick={() => setFilter([id])}
                className="filter"
              >
                <p
                  className={`filter-item ${
                    filter.length === 1 && filter[0] === id ? 'selected' : ''
                  } ${filter[0] + id}`}
                  style={{
                    color:
                      !showAll && filter[0] === id
                        ? getTeamData(slug).color
                        : '#0E1035',
                  }}
                >
                  {title}
                </p>
              </div>
            ))}
            <div className="divider" />
            <div
              onClick={() => setFilter(categories.map((c) => c.id))}
              className="filter"
            >
              <p
                className={`filter-item filter-all ${
                  showAll ? 'selected' : ''
                }`}
              >
                Alles tonen
              </p>
            </div>
          </div>
        )}
        {showBlogs.length === 0 && (
          <h4 className="emptyBlogs">
            Binnenkort verschijnen hier de eerste blogposts. Houd deze pagina
            dus zeker in de gaten!
          </h4>
        )}
        {showBlogs.length > 0 && (
          <div className="grid">
            {showBlogs.map((entry) => (
              <BlogOverviewBlock
                key={entry.id}
                entry={entry}
                cta={cta.cta[0]}
                hideSocials={hideSocials}
              />
            ))}
          </div>
        )}
        <CallToAction
          className={`load-more ${
            showBlogs.length < totalCount ? 'show' : 'hide'
          }`}
          action={() =>
            fetchMore({
              variables: {
                offset: showBlogs.length,
                limit: limit || 4,
                lang: router.locale === 'en' ? 2 : 1,
              },
              updateQuery: (extraBlogs) => {
                setShowBlogs([...showBlogs, ...extraBlogs]);
              },
              context: router,
            })
          }
          title={loadMore[0].title}
          ctaType={ctaType.plainText}
        />
      </section>
    );
  }
};

export default BlogList;
